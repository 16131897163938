import { useContext, createContext, useState, useEffect } from "react";
import { accountAuthenticatedRequest, accountLogoutRequest } from "./api/account";


const AuthContext = createContext();

const AuthProvider = ({ children }) => {

    // This state will only persist if navigation is done via the Route
    // Page reloads reset this value AS THEY SHOULD
    const [authenticated,setAuthenticated] = useState(false);

    // Loading state can be used to prevent rendering until the authentication of
    // the user has been determined / fetched from the backend
    const [loading,setLoading] = useState(true);

    const handleAuthResponse = (response) => {
        if (response.data) {
            if (response.data.success === true) {
                setAuthenticated(true);
                setLoading(false);
            }
        }
        
    }
    const handleAuthError = (error) => {setLoading(false);}

    // Need to refetch authentication on page reloads
    useEffect(() => {
        accountAuthenticatedRequest(handleAuthResponse,handleAuthError);
    }, []);

    const logout = (navigate,redirectLocation='/') => {

        const handleResponse = (response) => {
            setAuthenticated(false);
            navigate(redirectLocation);
            window.location.reload();
        }
        const handleError = (error) => {}
    
        accountLogoutRequest(handleResponse,handleError);
    }

    return (
        <AuthContext.Provider value={{ authenticated, setAuthenticated, loading, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};