import {useState} from 'react';
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    Stack,
    TextField,
    Typography
} from '@mui/material';

import AccountCircle from '@mui/icons-material/AccountCircle';
import LoadingButton from '@mui/lab/LoadingButton';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { ErrorDisplay, SuccessDisplay } from '../../../components/status';

import ReCaptchaButton from './recaptcha';
import { formInputStyle } from '../../../style/forms';
import { GoogleLogin, GoogleSignUp } from './google-account';
import { SlimLink, TogglePasswordAdornment } from '../../../components/buttons';
import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from '../../../config/account';

/*
handleSubmit - Function to handle submit clicked
handleSignupButton - Function to handle sign up button clicked
handleForgotPasswordButton - Function to handle forgot password button clicked
submitDisabled - Boolean to set submit button enabled or disabled
error - Boolean to set to True if error occurred
*/
export function Login({handleSubmit,handleSignupButton,handleForgotPasswordButton,submitDisabled,error,setError})
{
    const theme = useTheme();

    const [isChecked, setIsChecked] = useState(false); 
    const [formData, setFormData] = useState(
        {
            email: '',
            password: '',
            google_account: false,
            remember_me: false
        }
    );
    const [showPassword,setShowPassword] = useState(false);

    const handleFormChange = (event) => {
        
        const field = event.target.id;
        let value = event.target.value;

        if (field == 'remember_me') {
            setIsChecked(event.target.checked);
            value = event.target.checked;
        }
            
        setFormData({ ...formData, [field]: value});
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <AccountCircle fontSize="large" sx={{ height: 40 }} />
                <Typography component="h1" variant="h5" gutterBottom>
                    Sign in
                </Typography>
                <GoogleLogin handleSubmit={handleSubmit} setError={setError} />
                <Stack sx={{width:'100%'}}>
                    <Divider orientation="horizontal" sx={{ borderBottomWidth: '2px',mt:1.5 }}>
                        or
                    </Divider>
                </Stack>
                <Box component="form" onSubmit={(event) => {handleSubmit(event,formData)}} noValidate>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        inputProps={{ style: formInputStyle(theme) }}
                        onChange={handleFormChange}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        autoComplete="current-password"
                        inputProps={{ style: formInputStyle(theme) }}
                        onChange={handleFormChange}
                        InputProps = {
                            {
                              endAdornment: (
                                <TogglePasswordAdornment 
                                showPassword={showPassword}
                                setShowPassword={setShowPassword}
                                />
                              )
                            }
                        }
                    />
                    <FormControlLabel
                        control={
                        <Checkbox 
                        id="remember_me" 
                        color="primary"
                        value={isChecked.toString()} // Convert the state value to a string
                        checked={isChecked} // Set the checkbox state based on the `isChecked` state
                        onChange={handleFormChange}
                        />
                        }
                        label="Remember me"
                    />
                    {error &&
                        <ErrorDisplay error={error} />
                    }
                    <LoadingButton
                        type="submit"
                        disabled={submitDisabled}
                        loading={submitDisabled}
                        fullWidth
                        variant="contained"
                        sx={{
                            mt: 3,
                            mb: 2,
                            animation: error ? 'shake 1s' : 'none'
                        }}
                    >
                        Sign In
                    </LoadingButton>
                    <Stack direction="column" alignItems="center" spacing={1}>
                        <Button variant="text" onClick={handleForgotPasswordButton}>
                            Forgot password?
                        </Button>
                        <Button variant="text" onClick={handleSignupButton}>
                            {"Don't have an account? Sign Up"}
                        </Button>

                    </Stack>
                </Box>
            </Box>
        </>
    )
}

/*
handleSubmit - Function to handle submit clicked
handleLoginButton - Function to handle sign in button clicked
handleForgotPasswordButton - Function to handle forgot password button clicked
submitDisabled - Boolean to set submit button enabled or disabled
error - Boolean to set to True if error occurred
*/
export function Signup({handleSubmit,handleSignInButton,submitDisabled,error,setError})
{
    const theme = useTheme();

    const [promotionalEnabled, setPromotionalEnabled] = useState(true); 
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [isPasswordLengthValid, setIsPasswordLengthValid] = useState(true);
    const [acceptedPrivacyAndTerms,setAcceptedPrivacyAndTerms] = useState(false);
    const [validReCaptcha, setValidReCaptcha] = useState(false);

    const [showPassword,setShowPassword] = useState(false);
    const [showConfirmationPassword,setShowConfirmationPassword] = useState(false);

    // Set password confirmation to be garbage so it doesn't match password until user makes input
    const [formData, setFormData] = useState(
        {
            first: '',
            last: '',
            username: '',
            email: '',
            password: '',
            passwordConfirmation: 'dsdfvdfbsdfbbdsbfdsf',
            promotional: true,
            privacy_and_terms: false,
            google_account: false,
            recaptcha_response: ''
        }
    );

    const handleFormChange = (event) => {
        
        const field = event.target.id;
        let value = event.target.value;

        // Check if passwords match
        if (field === 'password_confirmation') {
            setPasswordsMatch(value === formData.password);
        }
        else if (field === 'password') {
            setPasswordsMatch(value === formData.passwordConfirmation);
            setIsPasswordLengthValid(value.length >= MIN_PASSWORD_LENGTH);
        }
        else if (field == 'promotional') {
            setPromotionalEnabled(event.target.checked);
            value = event.target.checked;
        } else if (field === 'privacy_and_terms') {
            setAcceptedPrivacyAndTerms(event.target.checked);
            value = event.target.checked;
        }
            
        setFormData({ ...formData, [field]: value});
    };

    const handleReCaptcha = (authkey) => {
        // authkey will be null when unchecked from expiration
        setValidReCaptcha(authkey ? true : false);
        setFormData({ ...formData, ['recaptcha_response']: authkey});
    }

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5" gutterBottom>
                    Welcome
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Automatically enrolled in 7 day free trial upon sign up
                </Typography>
                <GoogleSignUp handleSubmit={handleSubmit} setError={setError} />
                <Stack sx={{width:'100%'}}>
                    <Divider orientation="horizontal" sx={{ borderBottomWidth: '2px',mt:1.5 }}>
                        or
                    </Divider>
                </Stack>
                <Box component="form" noValidate onSubmit={(event) => {handleSubmit(event,formData)}} sx={{ mt: 1.5 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="given-name"
                                name="firstName"
                                required
                                fullWidth
                                id="first"
                                label="First Name"
                                autoFocus
                                inputProps={{ style: formInputStyle(theme) }}
                                onChange={handleFormChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="last"
                                label="Last Name"
                                name="last"
                                autoComplete="family-name"
                                inputProps={{ style: formInputStyle(theme) }}
                                onChange={handleFormChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="username"
                                label="Username"
                                name="username"
                                autoComplete="username"
                                inputProps={{ style: formInputStyle(theme) }}
                                onChange={handleFormChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                inputProps={{ style: formInputStyle(theme) }}
                                onChange={handleFormChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                    required
                                    fullWidth
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    label="Password"
                                    name="password"
                                    autoComplete="off"
                                    inputProps={{ style: formInputStyle(theme) }}
                                    onChange={handleFormChange}
                                    InputProps = {
                                        {
                                          endAdornment: (
                                            <TogglePasswordAdornment 
                                            showPassword={showPassword}
                                            setShowPassword={setShowPassword}
                                            />
                                          ),
                                            minLength: MIN_PASSWORD_LENGTH,  // Set the minimum length for the password
                                            maxLength: MAX_PASSWORD_LENGTH,  // Set the minimum length for the password
                                        }
                                    }
                                />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                type={showConfirmationPassword ? 'text' : 'password'}
                                id="password_confirmation"
                                label="Confirm Password"
                                name="password_confirmation"
                                autoComplete="off"
                                inputProps={{ style: formInputStyle(theme) }}
                                onChange={handleFormChange}
                                InputProps = {
                                    {
                                      endAdornment: (
                                        <TogglePasswordAdornment 
                                        showPassword={showConfirmationPassword}
                                        setShowPassword={setShowConfirmationPassword}
                                        />
                                      ),
                                      minLength: MIN_PASSWORD_LENGTH,
                                      maxLength: MAX_PASSWORD_LENGTH
                                    }
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center'}}>
                            {!isPasswordLengthValid && (
                                <Typography display="block" variant="body" color="error" >
                                    Password must be at least {MIN_PASSWORD_LENGTH} characters
                                </Typography>
                            )}
                            {!passwordsMatch && (
                                <Typography display="block" variant="body" color="error">
                                    Confirmation Password does not match
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                id='checkbox'
                                name='check'
                                control={
                                    <Checkbox
                                        id='promotional'
                                        value={promotionalEnabled.toString()} // Convert the state value to a string
                                        checked={promotionalEnabled} // Set the checkbox state based on the `promotionalEnabled` state
                                        color="primary"
                                        onChange={handleFormChange}
                                    />
                                }
                                label="I want to receive inspiration, marketing promotions and updates via email."
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                id='checkbox'
                                name='privacy_and_terns'
                                control={
                                    <Checkbox
                                        id='privacy_and_terms'
                                        value={acceptedPrivacyAndTerms.toString()}
                                        checked={acceptedPrivacyAndTerms}
                                        color="primary"
                                        onChange={handleFormChange}
                                    />
                                }
                                label={
                                    <Typography>
                                        I have read and agree to Solved Sports' <SlimLink href="/privacy-policy" sx={{mr:0.5}}>Privacy Policy</SlimLink> 
                                        and <SlimLink href="/terms-of-use">Terms of Use</SlimLink>
                                    </Typography>
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ReCaptchaButton handleReCaptcha={handleReCaptcha} />
                        </Grid>
                        {error &&
                        <Grid item xs={12} sx={{textAlign: 'center'}}>
                            <ErrorDisplay error={error} />
                        </Grid>
                        }
                    </Grid>
                    
                    <Button
                        type="submit"
                        disabled={submitDisabled || !passwordsMatch || !acceptedPrivacyAndTerms || !validReCaptcha}
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {submitDisabled ? 'Registering...' : 'Sign Up'}
                    </Button>
                    <Stack direction="column" alignItems="center">
                        <Button variant="text" onClick={handleSignInButton}>
                            {"Already have an account? Sign in"}
                        </Button>
                    </Stack>
                </Box>
            </Box>
        </>
    )
}

export function ForgotPassword({handleSubmit,handleSignInButton,emailSent}){

    const theme = useTheme();

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center'
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'white' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5" gutterBottom>
                    Forgot Your Password?
                </Typography>
                <Box sx={{maxWidth: 350}}>
                    <Typography variant="body">
                        Enter your email to receive a temporary link to reset your password. 
                    </Typography>
                </Box>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        inputProps={{ style: formInputStyle(theme) }}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={emailSent}
                    >
                        Reset Password
                    </Button>
                    {emailSent && 
                    <SuccessDisplay message={"Sent email"} Icon={CheckCircleIcon} />
                    }
                </Box>
                <Grid container>
                    <Grid item xs={12} sx={{textAlign: 'center'}}>
                        <Button variant="text" onClick={handleSignInButton}>
                            {"Remember Your Password?"}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}