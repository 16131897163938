import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';


import {
    Stack
} from '@mui/material';

// Basic Style Imports
import '../../../style/landing.css'
import '../../../style/index.css'

// Basic Component Imports
import { Header } from '../components/header';

import { Footer } from '../../../components/footer';

import { SubscriptionConflictSnackbar } from '../../../components/snackbars';
import { FeaturesWalkthrough } from '../components/features-walkthrough';
import { CommonFAQs } from '../components/common-faqs';
import { ResponsiveContainer } from '../../../components/responsive';
import { ExpiredSubscriptionPopup } from '../components/popups';


export default function LandingPage() {
    
    ReactGA.send({
        hitType: "pageview",
        page: "/",
        title: "Landing Page",
    });

    let location = useLocation();

    // Controls subscription conflict snackbar being shown based on subscription_conflice location
    // state
    const [subscriptionConflictSnackbarOpen,setSubscriptionConflictSnackbarOpen] = useState(false);

    // Controls subscription expired popup being shown based on subscription_expired location
    // state
    // The location state is only set after login, so this won't occur every time the landing page 
    // is visited
    const [expiredSubscription,setExpiredSubscription] = useState(false);

    // Will only be true if expiredSubscription is true. Just tells us if the expired subscription is
    // an expired trial to add specific messaging to popup.
    // The location state is only set after login, so this won't occur every time the landing page 
    // is visited
    const [expiredTrial,setExpiredTrial] = useState(false);

    useEffect(() => {
        setSubscriptionConflictSnackbarOpen(location.state !== null ? location.state.subscription_conflict : false);
        setExpiredSubscription(location.state !== null ? location.state.expired_subscription : false);
        setExpiredTrial(location.state !== null ? location.state.expired_trial : false);
    },[location]);

    return (
        <ResponsiveContainer>
            <Stack alignItems="center" spacing={3}>
                <Header />
                <FeaturesWalkthrough />
                <CommonFAQs />

                <Footer />
            </Stack>
            <SubscriptionConflictSnackbar open={subscriptionConflictSnackbarOpen} setOpen={setSubscriptionConflictSnackbarOpen} />
            <ExpiredSubscriptionPopup open={expiredSubscription} setOpen={setExpiredSubscription} expiredTrial={expiredTrial}/>
        </ResponsiveContainer>
    );
}
