import {
    Box
} from '@mui/material';
  
const images = require.context('../../../../assets/graphics/prediction-results', false, /\.png$/);

export function PredictionResultsImage(props){

    const {src,alt,sx,...otherProps} = props;

    let logoSrc;
    try {
        logoSrc = images('./'+src);
    }
    catch {
        logoSrc = null;
    }

    return (
        <Box
            component="img"
            alt={alt ? alt : "predictions-tutorial"}
            src={logoSrc}
            sx={{
                borderRadius: 3,
                ...sx
            }}
            {...otherProps}
        />
    )
}