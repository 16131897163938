import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import {Box} from '@mui/material';
import { ForgotPassword } from '../components/user';
import { ResponsiveContainer } from '../../../components/responsive';
import { accountForgotPasswordRequest } from '../../../hooks/api/account';


export default function ForgotPasswordPage() {

    const navigate = useNavigate();

    const [emailSent,setEmailSent] = useState(false);

    const handleResponse = (response) => {
        setEmailSent(true);
    }
    const handleError = (error) => {}

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const params = {
            email: data.get('email'),
        }
        accountForgotPasswordRequest(params,handleResponse,handleError);
    };

    const handleSignInButton = () => {
        navigate('/login');
    }

    return (
        <ResponsiveContainer>
            <Box component="div" sx={{mt: 8}}>
                <ForgotPassword handleSubmit={handleSubmit} handleSignInButton={handleSignInButton} emailSent={emailSent} />
            </Box>
        </ResponsiveContainer>
    );
}