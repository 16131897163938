import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Grid,
    Stack,
    Typography
} from '@mui/material';

const steps = [
    {
        title: 'Choose Model Type',
        text: 'We offer team and player prop models'
    },
    {
        title: 'Create Model',
        text: 'Choose from an extensive list of common and advanced metrics, then we generate a Machine Learning model just for you'
    },
    {
        title: 'Save Model',
        text: 'If you like your model, save it to your account'
    },
    {
        title: 'Run Predictions',
        text: 'Use your custom model to generate predictions for any game'
    },
    
]

export function FeaturesWalkthrough(){

    const theme = useTheme();

    return (
        <Stack direction="column" spacing={5} sx={{borderLeft: 3, borderRight: 3, borderRadius: 4, borderColor: theme.palette.secondary.main}}>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item textAlign="center">
                    <Typography variant="h4" sx={{p: 2,borderBottom: 2}} id="how-it-works">How it works</Typography>
                </Grid>
            </Grid>
            <Grid container columnGap={3} rowGap={5} alignItems="flex-start" justifyContent="center">
                {steps.map((step, index) => (

                    <Grid item xs={12} sm={5} md={3} key={index}>
                        <FeatureDisplay
                        key={index}
                        step_number={index+1}
                        title={step.title}
                        text={step.text}
                        />
                    </Grid>
                
                ))}
            </Grid>
        </Stack>
    )
}

function FeatureDisplay({step_number,title,text}){

    const theme = useTheme();

    const sidePadding = {xs:3,md:1};

    return (
        <Stack direction="column" spacing={1.5} alignItems="center" justifyContent="center" textAlign="center">
            <Avatar sx={{backgroundColor: theme.palette.primary.main, height: 50, width: 50, fontSize: 'large'}} aria-label="step-number">
                {step_number}
            </Avatar>
            <Typography variant="h6">{title}</Typography>
            <Typography variant="body" sx={{pl:sidePadding,pr:sidePadding}}>{text}</Typography>
        </Stack>
    )
}