import {useState} from 'react';

import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';

import {
  ListItemIcon,
  ListItemText
} from '@mui/material';

import {
  Divider,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Tooltip
} from '@mui/material';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { profileNavItems } from '../../navigation/components/navbar';

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(7),
        },
      }),
    },
  }),
);

export function ProfileDrawer( {selectedButton,setSelectedButton} ) {

  const [open, setOpen] = useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleClick = (event,newSelection) => {
    setSelectedButton(newSelection);
  };

  return(
    <>
        <Drawer variant="permanent" open={open}>
            <Toolbar
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                px: [1],
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                  ...(open && { display: 'none' }),
                }}
              >
                <ChevronRightIcon />
              </IconButton>
              <IconButton 
                onClick={toggleDrawer}
                sx={{
                  ...(!open && { display: 'none' }),
                }}
              >
                <ChevronLeftIcon />
              </IconButton>
            </Toolbar>
            <Divider />
            <ToggleButtonGroup orientation="vertical" exclusive value={selectedButton} onChange={handleClick}
            sx={{'& .MuiToggleButton-root' : {border:'none', textTransform : 'none'} ,
            '& .MuiListItemIcon-root': {justifyContent:'center'},
            '& .MuiListItemText-root': {display: 'inline-flex'}
            }}
            >
              {profileNavItems.map((navBarItem, index) => (
              <ProfileSidebarMenuItem
              key={index}
              icon={navBarItem.icon}
              text={navBarItem.text}
              />
              ))}
            </ToggleButtonGroup>
        </Drawer>
    </>
  )
}

function ProfileSidebarMenuItem({text,icon}){

  return (
    <>
      <ToggleButton value={text}>
        <Tooltip title={text} >
          <ListItemIcon>
            {icon}
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary={text}/>
      </ToggleButton>
    </>
  )
}
