

import {useState} from 'react';
import {
  Button,
  IconButton,
  DialogContent,
  DialogTitle,
  Stack,
  Typography
} from '@mui/material';
import { useTheme, } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { ResponsiveDialog } from '../../../../components/responsive';

export function ExportPredictionsButton({handleExportData}){

    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

    const [popupOpen,setPopupOpen] = useState(false);

    const handleButtonClicked = () => {
        setPopupOpen(true);
        // TODO - Actually use handleExportData function
    }

    return (
        <>
        <Button
            variant="contained"
            onClick={handleButtonClicked}
            startIcon={largeScreen && <FileDownloadIcon />}
            id="predictions-download"
        >
            {largeScreen ? "Export" : <FileDownloadIcon />}
        </Button>
        <ExportPredictionsPopup open={popupOpen} setOpen={setPopupOpen}/>
        </>
    )
}


export function ExportPredictionsPopup({open,setOpen}){

    const theme = useTheme();
  
    const handleClose = () => {
      setOpen(false);
    }
  
    return (
      <ResponsiveDialog open={open} handleClose={handleClose}>
        <IconButton
            onClick={handleClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.primary.main,
                zIndex: 2
            }}
        >
            <CloseIcon />
        </IconButton>
        <DialogTitle>
            Export Predictions Coming Soon…
        </DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={2} width='100%'>
            <Typography variant="body">
              We’re working on this and talking to users about what they want.
              If you have a specific vision, send us an email at info@solvedsports.com or send
              a message in the Discord.
            </Typography>
          </Stack>
        </DialogContent>
        
      </ResponsiveDialog>
    )
  }
