import { useParams, Navigate } from "react-router-dom";
import ModelSummary from '../model-summary/pages/model-summary';

const allowedTargets = ["player", "team"];

const ModelSummaryWrapper = () => {
    const { target } = useParams();
    if (!allowedTargets.includes(target)) {
        return <Navigate to="/error" />;
    }

    return <ModelSummary target={target}/>;
};

export default ModelSummaryWrapper;