
import {
    Stack,
    Typography
  } from '@mui/material';
export const selectAllFaq = (
    <Stack direction="column" spacing={1}>
    <Typography>
        No. Selecting all stats won’t be the most accurate model. Too many
        features in a model can lead to overfitting. Meaning that the model will become really good
        at what it knows from the past, but not as good at predicting future games/props.
    </Typography>
    <Typography>
        An example would be logo color. We could add that as a stat for you to use, but we all 
        know that logo color would not help a model predict games/props, and it might actually lead
        to more inaccurate predictions.
    </Typography>
    </Stack>
);