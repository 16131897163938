import { TutorialCarousel, CarouselItem, PredictionResultsTutorialPopupButton } from "../common/carousel-tutorial"

function TeamPredictionsTutorialCarousel(props){

    var items = [
        {
            name: "Games and Predicted Scores",
            description: "Your model generates a predicted score for each team in a game. \
                Those predicted scores can be seen in the left most column of the table.",
            imageSrc: "TeamPredictionResultsFirst.png"
        },
        {
            name: "Predicted Spread and Total",
            description: "From the predicted final score, a predicted spread and \
                total can be calculated. Use the dropdown above the table to toggle back and forth \
                between spreads and totals.",
            imageSrc: "TeamPredictionResultsSecond.png"
        },
        {
            name: "Prediction Value",
            description: "The difference between your model's predicted line and the sportsbooks' line represents the likelihood \
            for a winning bet. Differences that are large enough to have value are colored in green. These are the lines \
            your model recommends betting on.",
            imageSrc: "TeamPredictionResultsThird.png"
        },
        {
            name: "Compare to Sportsbook Lines",
            description: "We show you the best odds among popular sportsbooks. You can also look at each sportsbook individually \
            if you only use a single site.",
            imageSrc: "TeamPredictionResultsFourth.png"
        }
    ]
        
    
    return (
        <TutorialCarousel>
            {
                items.map( (item, i) => <CarouselItem key={i} item={item} /> )
            }
        </TutorialCarousel>
    )
}

export function TeamPredictionResultsTutorialPopupButton(){

    return (
        <PredictionResultsTutorialPopupButton
        content={<TeamPredictionsTutorialCarousel />}
        />
    )
}