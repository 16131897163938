import { useEffect, useState } from "react";
import { useLocation,useNavigate } from "react-router-dom";
import { ResponsiveContainer } from '../../../components/responsive';
import { PlayerPredictionResultsContainer } from "../components/player/player-prediction-results-container";
import { PredictionResultsFAQs } from "../components/common/faqs";

export default function PlayerPredictionResults() {
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;

    const [loading,setLoading] = useState(true);

    useEffect(() => {
        if ( state === null 
            || !state.league
            || !state.position
            || !state.prop
            || !state.date
        ) {
            navigate('/')
        }
        setLoading(false);
    }, [loading]);
    

    return (
        <ResponsiveContainer>
            {!loading &&
                <PlayerPredictionResultsContainer league={state.league} position={state.position} 
                    prop={state.prop} date={state.date} />
            }
            <PredictionResultsFAQs />
        </ResponsiveContainer>
    );
}