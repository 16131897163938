
import {
    Container,
    Grid,
    Typography
  } from '@mui/material';

import { useTheme } from '@mui/material/styles';

export function PageHeader({title,subtitle=""}){

    const theme = useTheme();

    return (
        <Grid container justifyContent="flex-start">
            <Grid item sx={{p: 0}}>
            <Container maxWidth="sm"
            sx={{
                textAlign: 'left', 
                pb: 1,
                borderLeft: 4, 
                borderTopLeftRadius: 16, 
                borderBottomLeftRadius: 16,
                borderColor: theme.palette.secondary.main,
                }}
            >
                <Typography variant="h4" gutterBottom sx={{p: 0.5}}>
                    {title}
                </Typography>
                {subtitle &&
                <Typography variant="body" gutterBottom>
                    {subtitle}
                </Typography>
                }
            </Container>
            </Grid>
        </Grid>
    )
}