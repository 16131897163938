import { useLocation,useNavigate } from 'react-router-dom';
import { useState,useEffect } from 'react';
import {
    CircularProgress, 
    Stack,
    Typography
  } from '@mui/material';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';

import { ResponsiveContainer } from "../../../components/responsive";
import { accountUnsubscribeRequest } from '../../../hooks/api/account';
import { ErrorDisplay, SuccessDisplay } from '../../../components/status';

export default function UnsubscribePage(){

    const location = useLocation();
    const navigate = useNavigate();

    const query = new URLSearchParams(location.search);
    const token = query.get('token');

    const [unsubscribed,setUnsubscribed] = useState(false);
    const [invalidToken,setInvalidToken] = useState(false);
    const [loading,setLoading] = useState(true);

    const errorMessage = invalidToken ?
    "Invalid link" : "Something went wrong";

    const handleResponse = (response) => {
        if (response.data) {
            setUnsubscribed(response.data.unsubscribed);
            setInvalidToken(response.data.invalid_token)
        }
        setLoading(false);
    }
    const handleError = (error) => {
        setLoading(false);
    }

    useEffect(()=>{
        if (!token) {
            navigate('/');
        }

        // Unsubscribe
        const params = {
            token: token
        }
        accountUnsubscribeRequest(params,handleResponse,handleError);
    },[])

    return (
        <ResponsiveContainer>
            <Stack direction="column" spacing={1} alignItems="center">
            {loading ?
            <>
                <Typography>Unsubscribing...</Typography>
                <CircularProgress sx={{pb: 4}}/>
            </>
            :
            unsubscribed ?
            <SuccessDisplay message={"Successfully Unsubscribed"} Icon={UnsubscribeIcon} />
            :
            <ErrorDisplay error={errorMessage}/>
            }
            </Stack>

        </ResponsiveContainer>
    )
}