
import {useMemo,useState,useEffect} from 'react';
import { useTheme, } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Stack,
  Typography
} from '@mui/material';

import dayjs from 'dayjs';
import { 
    BasePredictionResultsMRT,
    DesktopOddsColumnWidth,
    MobileOddsColumnWidth,
    validSportsbooks 
} from '../../../../components/table';

import { TeamLogo } from '../../../../components/graphics/team-logo';
import { BestOddsTotalBlockDisplay, TotalBlockDisplay } from '../common/prediction-displays';
import { FilterMenu, SortByMenu } from '../../../../components/filter-menu';
import { SingleSelect } from '../../../../components/menus';
import {descendingSortFunction,ascendingSortFunction} from '../../../../utils/sorting';
import { ResponsiveSportsbookLogo } from '../../../../components/graphics/sportsbook-logo';
import { ExportPredictionsButton } from '../common/export-predictions';
import { PROPS_WITHOUT_ODDS } from '../../../../config/models';


function ShowPlayersWithOddsCheckbox({hidePlayersWithoutodds,handleCheckboxFilterChanged}) {
    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={hidePlayersWithoutodds}
                    onChange={handleCheckboxFilterChanged}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            }
        label="Only show players with odds"
        />
    )
}

function PlayerPredictionResultsFilter({
    largeScreen,sportsbookSelection,setSportsbookSelection,hidePlayersWithoutodds,handleCheckboxFilterChanged,
    predictionData,setFilteredPredictionsData
}){

    ////////////////////////////////////////////////////////
    // Filters
    // Note: Sportsbook filter is tied into the
    // table and can not be moved into this component
    ////////////////////////////////////////////////////////
    const [showValueBetsOnly,setShowValueBetsOnly] = useState(false);
    const valueFilterFn = ({predicted_odds_values}) => predicted_odds_values.max_value > 0 || !showValueBetsOnly;

    // If columns change, we need to reapply filters
    useEffect(() => {
        if (predictionData.length !== 0) {
            let filteredData = predictionData.filter(valueFilterFn);
            setFilteredPredictionsData(filteredData);
        }
     },[showValueBetsOnly]);

    return (
        <FilterMenu>

            {/* The below items are only shown on small screens */}
            {!largeScreen &&
            // Don't give users this option until the screen is smaller
            <MenuItem>
                <SingleSelect 
                label="Sportsbook"
                value={sportsbookSelection}
                availableValues={['best_odds'].concat(validSportsbooks.map(value => value.key))}
                availableValuesDisplay={['Best Odds'].concat(validSportsbooks.map(value => value.display))}
                onChangeFn={(event)=> setSportsbookSelection(event.target.value)}
                />
            </MenuItem>
            }

            {/* The below items are always shown in the filter */}
            <MenuItem>
                <ShowPlayersWithOddsCheckbox
                hidePlayersWithoutodds={hidePlayersWithoutodds}
                handleCheckboxFilterChanged={handleCheckboxFilterChanged}
                />
            </MenuItem>
            <MenuItem>
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={showValueBetsOnly}
                            onChange={(event) => setShowValueBetsOnly(event.target.checked)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                        }
                    label="Show value bets only"
                    />
                </MenuItem>
        </FilterMenu>
    )
}

function PlayerPredictionResultsSortBy({filteredData,setFilteredData}){

        ////////////////////////////////////////////////////////
        // Sort functions
        ////////////////////////////////////////////////////////
    
        const options = [
            {
                title: "Highest Value",
                sortFn: (a,b) => 
                    descendingSortFunction(a.predicted_odds_values.max_value,b.predicted_odds_values.max_value)
            },
            {
                title: "Lowest Value",
                sortFn: (a,b) => 
                    ascendingSortFunction(a.predicted_odds_values.max_value,b.predicted_odds_values.max_value)
            },
        ];
        
        // Set selected index to null. Not sorting anything to start.
        const [selectedIndex,setSelectedIndex] = useState(null);
        
        const sortData = (sortFn) => {
            const localFilteredData = filteredData.slice().sort(sortFn);
            setFilteredData(localFilteredData);
        }

        const handleChange = (event) => {
            const index = event.currentTarget.value;
            sortData(options[index].sortFn);
            setSelectedIndex(index);
        }
    
        return (
            <SortByMenu
            selectedIndex={selectedIndex}
            options={options}
            onChangeFn={handleChange}
            />
        )
}

export function PlayerPredictionResultsTable({league,position,prop,date,predictionData,units,loading}){

    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

    const columnVisibilityDict = {
        "best_odds" : true,
        "draftkings" : true,
        "fanduel"   : true,
        "wynnbet"   : true,
        "betmgm"    : true,
        "bovada"     : true,
        "pointsbet" : true,
        "caesars"   : true,
        "mybookie"  : true,
    }

    ////////////////////////////////////////////////////////
    // States
    ////////////////////////////////////////////////////////
    const [filteredPredictionsData,setFilteredPredictionsData] = useState(predictionData);
    const [hidePlayersWithoutodds, setHidePlayersWithoutodds] = useState(
        !PROPS_WITHOUT_ODDS.includes(prop)
    );

    const oddsExistFilterFn = (a) => a.odds_exist;

    useEffect(() => {
        // Filter the data to only show players with odds on page load
        let localfilteredPredictionsData = hidePlayersWithoutodds && !loading ? 
            predictionData.filter(oddsExistFilterFn)
            : predictionData;
        setFilteredPredictionsData(localfilteredPredictionsData);
    }, [predictionData,loading]);

    const [sportsbookSelection,setSportsbookSelection] = useState("best_odds");
    const [columnVisibility,setColumnVisibility] = useState(columnVisibilityDict);

    ////////////////////////////////////////////////////////
    // Column Definitions
    ////////////////////////////////////////////////////////
    const oddsColumnWidth = largeScreen ? DesktopOddsColumnWidth : MobileOddsColumnWidth;

    const columns = useMemo(
        () => [
          {
              // Set the row value for search, display is overriden by Cell below
              accessorFn: (row) => row.player.display_name,
              id: 'players',
              header: 'Players',
              size: largeScreen ? 200 : 150,
              enableSorting: false,
              enableColumnFilter: false,
              enableColumnPinning: true,
              Header: ({ column }) => (
                  <>
                      <Typography variant={largeScreen ? "body1" : "body2"}>
                          {dayjs(date).format('MMM Do, YYYY')}
                      </Typography>
                  </>
              ),
              Cell: ({ row }) =>
                  <>
                      <PlayerDisplay player={row.original.player} units={units} 
                      league={league} largeScreen={largeScreen}
                      />
                  </>
          },
          {
              accessorFn: (row) => row.predicted_odds, 
              id: 'predicted_prop',
              header: 'Prediction',
              size: oddsColumnWidth,
              enableSorting: false,
              enableColumnFilter: false,
              enableColumnPinning: true,
              Header: ({ column }) => (
                    <>
                        <Typography variant={largeScreen ? "h6" : "caption"}>{column.columnDef.header}</Typography>
                    </>
                ),
              Cell: ({ row }) =>
                  <>
                      <TotalBlockDisplay totals={row.original.predicted_odds}
                            // These have to be str indexed for some reason
                            overHasValue={row.original.predicted_odds_values.over['value_bool']}
                            underHasValue={row.original.predicted_odds_values.under['value_bool']}
                            debug={row.original.player.display_name}
                      />
                  </>
          },
          {
            accessorFn: (row) => row.best_odds, 
            id: 'best_odds',
            header: 'Best Odds',
            size: oddsColumnWidth,
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnPinning: false,
            Header: ({ column }) => (
                  <>
                      <Typography variant={largeScreen ? "h6" : "caption"}>{column.columnDef.header}</Typography>
                  </>
              ),
            Cell: ({ row }) =>
                <>
                    <BestOddsTotalBlockDisplay best_odds_totals={row.original.best_odds} />
                </>
        },
          ...validSportsbooks.map((sportsbook,index) => (
            {
            accessorFn: (row) => row.sportsbooks[sportsbook.display], 
            id: sportsbook.key,
            header: sportsbook.key,
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnPinning: false,
            size: oddsColumnWidth,
            Header: ({ column }) => (
                <>
                    <ResponsiveSportsbookLogo sportsbook={column.columnDef.header} desktopWidth={"100%"} mobileWidth={30} largeScreen={largeScreen} />
                </>
            ),
            Cell: ({ row }) => (
                <>
                    <TotalBlockDisplay totals={row.original.sportsbooks[sportsbook.display]} 
                    bestSportsbookOver={row.original.best_odds.over.sportsbooks.includes(sportsbook.display)}
                    bestSportsbookUnder={row.original.best_odds.under.sportsbooks.includes(sportsbook.display)}
                    debug={sportsbook.display+' '+row.original.player.display_name}
                    />
                </>
            )
            }
            ))
  
          ],
          [units,largeScreen]
      );

    const handleExportData = () => {
        // TODO 
    };

    const handleCheckboxFilterChanged = (event) => {
        const isChecked = event.target.checked;
        setHidePlayersWithoutodds(isChecked);

        // Filter based on original prediction data. This will
        // wipe out any sorts which is fine.
        const localfilteredPredictionsData = isChecked ? 
            predictionData.filter(oddsExistFilterFn)
            : predictionData;
        setFilteredPredictionsData(localfilteredPredictionsData)
        
    };

    const handleSportsbookSelected = (event) => {
        setSportsbookSelection(event.target.value);
    }

    const handleFilterMenuReset = () => {
        setHidePlayersWithoutodds(true);
        setSportsbookSelection(validSportsbooks[0]["key"]);
    }

    const updateColumnVisibilityResponsive = () => {
        if (largeScreen) {
            setColumnVisibility(columnVisibilityDict);
        }
        else {
            let localColumnVisibility = columnVisibilityDict;
            for (const [key, value] of Object.entries(localColumnVisibility)) {
                if (!key.includes('predicted') && !key.includes(sportsbookSelection)){
                    localColumnVisibility[key] = false;
                }
            }
            setColumnVisibility(localColumnVisibility);
        }
    }

    useEffect(() => {
        updateColumnVisibilityResponsive();
    }, [largeScreen,sportsbookSelection]);

    return (
        <>
        <Box component='div' sx={{width: largeScreen ? '90%' : null}}>
            <BasePredictionResultsMRT
                columns={columns}
                data={filteredPredictionsData}

                loading={loading}
                theme={theme}
                largeScreen={largeScreen}

                // Top Toolbar custom actions
                renderTopToolbarCustomActions={({ table }) => {

                    return (
                    <Box component="div" sx={{ display: 'flex', gap: '0.5rem'}}>
                        <Stack direction="row" spacing={1}>
                            <PlayerPredictionResultsFilter
                                largeScreen={largeScreen}
                                sportsbookSelection={sportsbookSelection}
                                setSportsbookSelection={setSportsbookSelection}
                                hidePlayersWithoutodds={hidePlayersWithoutodds}
                                handleCheckboxFilterChanged={handleCheckboxFilterChanged}
                                predictionData={predictionData}
                                setFilteredPredictionsData={setFilteredPredictionsData}
                            />
                            <PlayerPredictionResultsSortBy 
                                filteredData={filteredPredictionsData}
                                setFilteredData={setFilteredPredictionsData}
                            />
                            <ExportPredictionsButton handleExportData={handleExportData} />
                        </Stack>
                    </Box>
                    );
                }}

                state={{ columnVisibility, showSkeletons: loading, showGlobalFilter: true }}
            
                onColumnVisibilityChange={ setColumnVisibility }

                // Column Pinning
                enableColumnPinning={false}
                layoutMode='grid-no-grow'
                initialState= {{
                    columnPinning: { left: ['players','predicted_prop'] },
                    showGlobalFilter: true
                }}

            /> {/* End of table definition */}
        </Box>    
        </>
        )
}

function PlayerDisplay({player,units,league,largeScreen}) {

    const typographyVariant = largeScreen ? "body1": "body2";
    const logoWidth = largeScreen ? 25 : 20;

    return (
        <>
            <Stack direction="column" height="100%" justifyContent="center" spacing={1}>
                <Stack height="50%" justifyContent="center">

                        <Stack direction="column" justifyContent="center" spacing={1}>
                            <Stack direction="row" spacing={{xs:0.5,sm:1}} justifyContent="flex-start" alignItems="center">
                                <TeamLogo team={player.location+" "+player.nickname} league={league} width={logoWidth}/>
                                
                                <Typography variant={typographyVariant} >
                                    {largeScreen ? player.display_name : player.display_name_abbreviated}
                                </Typography>
                                    
                            </Stack>
                            <Stack direction="row" spacing={{xs:0.5,sm:1}} justifyContent="flex-start" alignItems="center">
                                <Box sx={{width: logoWidth}}></Box>
                                <Typography variant={typographyVariant}>
                                        {player.prediction + " " + units}
                                </Typography>
                            </Stack>
                        </Stack>
                        
                    
                </Stack>
            </Stack>
        </>
    )
}



