import {useState} from 'react';
import {
    Button,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';

import { ResponsiveDialog } from '../../../components/responsive';
import { trialUpgrade } from '../../../hooks/api/payment';
import { ErrorDisplay } from '../../../components/status';


export function CancelTrialPopup({open,setOpen,productLookupKey}){

    const [loading,setLoading] = useState(false);
    const [error,setError] = useState(null)
  
    const handleClose = () => {
      setOpen(false);
    }

    const warningIconDims = {xs: 40, md: 70};

    const handleResponse = (response) => {
        window.open(response.data.url, "_self");
    }
    const handleError = (error) => {
        setLoading(false);
        setError("Something went wrong");
    }

    const handleContinueToCancel = () => {
        setLoading(true);
        const params = {
            'lookup_key' : productLookupKey
        };

        trialUpgrade(params,handleResponse,handleError);
    }
  
    return (
      <ResponsiveDialog open={open}>
        <IconButton
            onClick={handleClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'primary',
                zIndex: 2
            }}
        >
            <CloseIcon />
        </IconButton>
        <DialogTitle sx={{textAlign: 'center', alignItems: 'center'}}>
                <WarningIcon color="warning" sx={{height: warningIconDims,width: warningIconDims}}/>
        </DialogTitle>
        <DialogContent>
            <Stack direction="column" spacing={2} width="100%" alignItems="center" textAlign="center">
                <Typography>
                    Upgrading your subscription will cancel your trial.
                    Are you sure you want to continue?
                </Typography>
                <LoadingButton
                variant="contained"
                onClick={handleContinueToCancel}
                loading={loading}
                >
                    Continue
                </LoadingButton>
                {error && <ErrorDisplay error={error} />}
            </Stack>
        </DialogContent>
        
      </ResponsiveDialog>
    )
  };