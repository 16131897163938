import {
    Box
} from '@mui/material';
  
const images = require.context('../../assets', false, /\.png$/);

export function SolvedSportsLogo(props){

    let logoSrc;
    try {
        logoSrc = images('./SolvedSportsLogo.png');
    }
    catch {
        logoSrc = null;
    }

    return (
        <Box
            component="img"
            alt="logo"
            src={logoSrc}
            {...props}
        />
    )
}