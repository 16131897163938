
import { Box, Typography } from '@mui/material';
import { ResponsiveContainer } from '../../../components/responsive';
import PricingContent from '../components/pricing';

export default function PricingPage(){

    return (
        <>
            <ResponsiveContainer>
                <Box sx={{width: '100%',textAlign: 'center'}}>
                    <Typography gutterBottom variant="h4" >
                        Select a plan to start getting a statistical edge
                    </Typography>
                </Box>
                
                <PricingContent />
            </ResponsiveContainer>
        </>
    );
}

