import {useState} from 'react';

import { useNavigate } from 'react-router-dom';

import {
    Box,
    Button,
    Collapse,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Stack
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import IconExpandLess from '@material-ui/icons/ExpandLess';
import IconExpandMore from '@material-ui/icons/ExpandMore';
import AccountCircle from '@mui/icons-material/AccountCircle';

import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from '../../../hooks/auth';
import { DropdownMenu } from '../../../components/menus';

export function ProfileMenu({profileNavItems}) {

    const auth = useAuth();
    const navigate = useNavigate();

    ////////////////////////////////////////////////////////
    // Menu setup
    ////////////////////////////////////////////////////////
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <>
        <Button
            variant="contained"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            sx={{borderRadius: 4 }}
        >
            <AccountCircle fontSize="large" sx={{ height: 30 }}/>
        </Button>
        <DropdownMenu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            sx={{
                '& .MuiPaper-root': {
                    minWidth: 200,
                    mt: 1
                }
            }}
        >

            {profileNavItems.map((navBarItem, index) => (
            <MobileNavBarMenuItem
            key={navBarItem.text+index}
            icon={navBarItem.icon}
            text={navBarItem.text}
            onClick={navBarItem.onClick}
            link={navBarItem.link}
            setTopLevelOpen={handleClose}
            />
            ))}
            <ListItem key={"Logout"} disablePadding sx={{color: 'red'}}>
                <ListItemButton onClick={()=> {handleClose();auth.logout(navigate)}}>
                <ListItemIcon>
                    <LogoutIcon sx={{color: 'red'}}/>
                </ListItemIcon>
                <ListItemText primary={"Logout"} />
                </ListItemButton>
            </ListItem>
        </DropdownMenu>
        </>
    );
}

const NavBarMenuProps = {
    "&:hover": {
        backgroundColor: 'transparent',
        textDecoration: 'underline',
        textUnderlineOffset: '0.8rem'
    },
    "&.Mui-selected": { 
        backgroundColor: 'transparent',
        textDecoration: 'underline',
        textUnderlineOffset: '0.8rem' 
    }
};

export function DesktopNavBar({navBarItems}){

    

    return (
        <>
        <Stack direction="row" spacing={2} justifyContent="flex-end">
            {navBarItems.map((navBarItem, index) => (
            <DesktopNavBarMenuItem
            key={navBarItem.text+index}
            text={navBarItem.text}
            onClick={navBarItem.onClick}
            link={navBarItem.link}
            items={navBarItem.items}
            />
            ))}
        </Stack>
        </>
    )
}

function DesktopNavBarMenuItem({text,onClick=null,link=null,items=null,topLevelItem=true,setAnchorElForAbove=null}){

    const navigate = useNavigate();

    const isExpandable = items && items.length > 0;

    ////////////////////////////////////////////////////////
    // Dropdown Menu setup
    ////////////////////////////////////////////////////////
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    function handleMenuItemClicked(event) {
        if (onClick){
            onClick();
        }
        if (link){
            navigate(link);
        }

        if (!onClick && !link)
        {
            // Must be a menu
            setAnchorEl(event.currentTarget);
        }
        else if (setAnchorElForAbove) 
        {
            // Must be a menu item, close menu
            setAnchorElForAbove(null);
        }
        
    }

    const sxProps = topLevelItem ? NavBarMenuProps : {};

    return (
        <>
        <MenuItem sx={{...sxProps,fontSize: 18}}
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleMenuItemClicked}
            selected={open && isExpandable}
            disableRipple
        >
        {text}            
        </MenuItem>
        {isExpandable &&
            <DropdownMenu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={{
                    '& .MuiPaper-root': {
                        minWidth: 200,
                        mt: 1
                    }
                }}
            >
                {items.map((navBarItem, index) => (
                <DesktopNavBarMenuItem
                    key={navBarItem+index}
                    text={navBarItem.text}
                    onClick={navBarItem.onClick}
                    link={navBarItem.link}
                    items={navBarItem.items}
                    topLevelItem={false}
                    setAnchorElForAbove={setAnchorEl}
                />
                ))}
            </DropdownMenu>
        }
        
        </>
    )
}

export function MobileNavBar({navBarItems}){

    const [open, setOpen] = useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    
    const DrawerList = (
        <Box sx={{ width: 250,p: 1 }} role="presentation">
        <List>
            {navBarItems.map((navBarItem, index) => (
            <MobileNavBarMenuItem
            key={navBarItem.text+index}
            setTopLevelOpen={setOpen}
            text={navBarItem.text}
            icon={navBarItem.icon}
            onClick={navBarItem.onClick}
            link={navBarItem.link}
            items={navBarItem.items}
            />
            ))}
        </List>
        </Box>
    );

    return (
        <>
        <IconButton onClick={toggleDrawer(true)}>
            <MenuIcon fontSize="large"/>
        </IconButton>
        <Drawer open={open} onClose={toggleDrawer(false)}>
            <Box sx={{display: 'flex',justifyContent: 'end',width:'100%'}}>
                <IconButton onClick={()=>{setOpen(false)}}>
                    <CloseIcon />
                </IconButton>
            </Box>
            {DrawerList}
        </Drawer>
        </>
    );
}

function MobileNavBarMenuItem({setTopLevelOpen,text,icon,onClick=null,link=null,items=null}){

    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const isExpandable = items && items.length > 0;
    
    function handleClick() {
        setOpen(!open);
        if (onClick){
            setTopLevelOpen(false);
            onClick();
        }
        if (link){
            setTopLevelOpen(false);
            navigate(link);
        }
        
    }

    return (
        <>
        <ListItem key={text} disablePadding>
            <ListItemButton onClick={handleClick}>
            {icon && 
            <ListItemIcon>
                {icon}
            </ListItemIcon>
            }
            <ListItemText primary={text} />
            {isExpandable && !open && <IconExpandMore />}
            {isExpandable && open && <IconExpandLess />}
            </ListItemButton>
        </ListItem>
        
        {isExpandable && open &&
            <>
            <Divider />
            <Collapse in={open} timeout="auto" unmountOnExit>
                {items.map((navBarItem, index) => (
                <MobileNavBarMenuItem
                    key={navBarItem.text+index}
                    setTopLevelOpen={setTopLevelOpen}
                    text={navBarItem.text}
                    icon={navBarItem.icon}
                    onClick={navBarItem.onClick}
                    link={navBarItem.link}
                    items={navBarItem.items}
                />
                ))}
            </Collapse>
            <Divider />
            </>
        }
        
        </>
    )
}