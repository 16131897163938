import { useEffect, useMemo, useState } from 'react';
import {
  Box, 
  Button, 
  Grid,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from "react-router-dom";

import { UserPopup } from '../../../auth/components/popups';
import { StatSelectionMRT, tableHeight } from '../../../../components/table';
import PaymentOptionsPopup from '../../../pricing/components/payment-popup';

import { teamStatisticsRequest, teamTrainRequest } from '../../../../hooks/api/team';
import { SelectedStatsContainer, CategoriesStack } from '../common/stat-selection';

export function TeamStatSelection({league}){

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));
  
    ////////////////////////////////////////////////////////
    // States
    ////////////////////////////////////////////////////////
    const [statsData, setStatsData] = useState([]);
    const [categories,setCategories] = useState([]);
    const [rowSelection, setRowSelection] = useState({});
    const [loading,setLoading] = useState(false);
    const [isTraining, setIsTraining] = useState(false); 
  
    // Popups
    const [userPopupOpen,setUserPopupOpen] = useState(false);
    const [pricingPopupOpen,setPricingPopupOpen] = useState(false);
  
    ////////////////////////////////////////////////////////
    // API
    ////////////////////////////////////////////////////////
  
    const handleStatisticsResponse = (response) => {
      const apiStatsData = response.data.stats;
      setStatsData(apiStatsData);
      setCategories(response.data.categories);
      setLoading(false);
    }
    const handleStatisticsError = (error) => {
      setLoading(false);
    }
  
    useEffect(() => {
      setLoading(true);
      const params = {
        league: league
      }
      teamStatisticsRequest(params,handleStatisticsResponse,handleStatisticsError);
  }, [league]);
  
    ////////////////////////////////////////////////////////
    // Column Definitions
    ////////////////////////////////////////////////////////
    const columns = useMemo(
      () => [
        {
          accessorKey: "statistic",
          header: "Statistic",
          size: isMobile ? 125: 200,
        },
        { // Combine as string for filtering/searching
          // but actual display is defined by Cell parameter.
          // Need to use row.orignal rather than Cell or renderedCellValue.
          accessorFn: (row) => row.category.join(','), 
          id: 'category',
          header: 'Category',
          filterVariant: 'multi-select',
          filterSelectOptions: categories,
          Cell: ({ row }) =>
            <CategoriesStack row={row} isMobile={isMobile} />
        }
      ],
      [categories,isMobile]
    );
  
    ////////////////////////////////////////////////////////
    // Run button handler function
    ////////////////////////////////////////////////////////
  
    const navigate = useNavigate();
  
    const handleTrainResponse = (response) => {
      setIsTraining(false);
      navigate('/model-summary/team');
    }
  
    const handleTrainError = (error) => {
      if (error.response) {
        if (error.response.data.csrf) {
          setIsTraining(false);
          setUserPopupOpen(true);
        } else if (error.response.status == 401 ) {
          // Unauthorized
          setIsTraining(false);
          setPricingPopupOpen(true);
        }
        else {
          // Something went wrong
        }
      }
    }
  
    const handleRunButtonClicked = () => {
      setIsTraining(true); // Set training state to true
      const params = {
        league: league
      }
      const requestBody = JSON.stringify({
        selectedStats: Object.keys(rowSelection),
      })
      
      teamTrainRequest(requestBody,params,handleTrainResponse,handleTrainError);
    };
  
  
  
    return (
      <>
        <Grid container spacing={{xs:3,sm:2}} direction={largeScreen ? "row" : "column"} justifyContent="center" sx={{mb: 5}}>
          <Grid item xs={4}>
            <SelectedStatsContainer rowSelection={rowSelection} setRowSelection={setRowSelection} isTraining={isTraining} />
          </Grid>
  
          <Grid item xs={8} justifyContent="center">
            {/* Stat Selection Table */}
            <Box component='div' className='onboarding-step-1'>
              <StatSelectionMRT
                columns={columns}
                data={statsData}
  
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
  
                loading={loading}
                height={tableHeight}
  
                isMobile={isMobile}
                theme={theme}
  
                getRowId= {(row) => row.statistic}
  
                mrtTheme={isMobile && {
                    baseBackgroundColor: theme.palette.background.default
                }}
  
                // Top Toolbar custom actions
                renderTopToolbarCustomActions={({ table }) => {
                return (
                    <div style={{ display: 'flex', gap: '0.5rem' }}>
                    <Button
                        color={theme.primary}
                        variant="contained"
                        onClick={handleRunButtonClicked}
                        disabled = {Object.keys(rowSelection).length === 0}
                        className="onboarding-step-3"
                    >
                        Run
                    </Button>
                    </div>
                );
                }}
  
                />
              </Box>
            </Grid>
          </Grid>
  
        {/* This popup will only try to train a model and are not logged in*/}
        <UserPopup open={userPopupOpen} setOpen={setUserPopupOpen} />
  
        {/* This popup will only get rendered if we are logged in and are unauthorized */}
        <PaymentOptionsPopup open={pricingPopupOpen} setOpen={setPricingPopupOpen} isLoggedIn={true} unauthorized={true}/>
      </>
    );
  }