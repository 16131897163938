
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Container,
    Divider,
    Typography
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export function FAQs({faqItems,styleProps={},className=""}) {

    return (
        <Container maxWidth="lg">
            <Box {...styleProps} component="div" className={className}>
                <Typography variant="h4" gutterBottom>FAQs</Typography>
                { faqItems.map( (item,index) => (
                <Accordion key={index}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    >
                    {item.summary}
                    </AccordionSummary>
                    <Divider orientation="horizontal" flexItem />
                    <AccordionDetails>
                        {item.details}
                    </AccordionDetails>
                </Accordion>
                ))}
            </Box>
        </Container>
    )
}

export function ModelSummaryFAQs(styleProps) {

    var faqItems = [
        {
            "summary" : "What is Test data?",
            "details" : "A small sample of games is separated out from training data to test how the \
                model performs with data that it hasn't seen before. Predictions are generated \
                from this test data without the model having knowledge of the actual score to emulate real \
                predictions"
        },
        {
            "summary" : "Histogram Plot Explained",
            "details" : "The difference between model predictions and actual results (score) \
                shown in a histogram. It is desirable to have the majority of this data close to zero."
        },
        {
            "summary" : "QQ Plot Explained",
            "details" : "Compares the ratio of predictions to actual score to the perfect model \
                of a 1:1 ratio. Good models follow the general trend of the slope of 1 line."
        },
        {
            "summary" : "Residuals Plot Explained",
            "details" : "The difference between model predictions and actual results (score) scaled \
                to account for the magnitude of the score. Scaling or standardization is important \
                because a prediction that is off by 4 matters a lot more for NFL games than NBA \
                games where the average scores are much different."
        },
        {
            "summary" : "Loss Plot Explained",
            "details" : "The model's error throughout training. A perfect model has 0 error, \
                and this is the model's progress towards achieving that, but no model will ever be perfect."
        },

    ]

    return (
        <>
            <FAQs faqItems={faqItems} styleProps={styleProps} />
        </>
    )
}
