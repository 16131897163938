
import {
    List,
    ListItem,
    ListItemText,
    Stack,
    Typography
} from '@mui/material';
import { FAQs } from '../../../components/onboarding/faqs';
import { ResponsiveContainer } from '../../../components/responsive';
import { FREE_TRIAL_DAYS } from '../../../config/account';
import { overallGradeInfo } from '../../../utils/common-copy';

export function CommonFAQs(styleProps) {

    const historicalProfitability_details = (
        <Stack direction="column" spacing={1}>
            <Typography>Yes.</Typography>
            <Typography>
                Every time a model is created, we calculate the historical profitability on
                up to 10 years of historical data. And you can view that model's historical profitability
                at any time from your account.
            </Typography>
        </Stack>

    );

    const freeTrial_details = (
        <Stack direction="column" spacing={1}>
            <Typography>No.</Typography>
            <Typography>
                We hate it when sites require a credit card for a Free Trial. Check out our features
                for {FREE_TRIAL_DAYS} days with no strings attached.
            </Typography>
        </Stack>

    );

    const goodModel_details = (
        <>
        <Typography>
            When you create a model, we provide you with a model summary
            with the following information:
        </Typography>
        <List sx={{ listStyle: "decimal", pl: 3 }}>
            <ListItem sx={{ display: "list-item" }}>
                <ListItemText 
                primary="Overall Grade" 
                secondary={overallGradeInfo}
                />
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
                <ListItemText 
                primary="Historical Profitability" 
                secondary="Breaks down your models profit by bet type, however this metric 
                is not completely reliable because it is completely automated and analytical betting
                insights should always be paired with human intuition."
                />
            </ListItem>
        </List>
        <Typography>Note: This summary information will always be available for any model you save to your account</Typography>
        </>
    );

    const howToMakeGoodModel_details = (
        <Stack direction="column" spacing={1}>
            <Typography>
                We recommend selecting a handful of relevant metrics to tailor your model for your specific needs. Tinker around
                with different stats and see how it effects the model's overall grade and historical profitability.
            </Typography>
        </Stack>

    );

    var faqItems = [
        {
            "summary" : "Does each model I make get checked for historical profitability?",
            "details" : historicalProfitability_details
        },
        {
            "summary" : 'Does the Free Trial require a credit card?',
            "details" : freeTrial_details
        },
        {
            "summary" : 'How do I know if I made a "good" model?',
            "details" : goodModel_details
        },
        {
            "summary" : 'How do I make an accurate model?',
            "details" : howToMakeGoodModel_details
        }
    ]

    return (
        <ResponsiveContainer>
            <FAQs faqItems={faqItems} styleProps={styleProps} />
        </ResponsiveContainer>
    )
}