import {useState} from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  Box,
  Stack,
} from '@mui/material';

import { ResponsiveContainer } from '../../../components/responsive';
import { ProfileDrawer } from '../components/drawer-menu';

// Component Imports
import AccountSettings from '../components/account-settings'
import AccountPayment from '../components/payments/account-payments';

import { navbarHeight } from '../../navigation/components/navbar';
import { ProfileModelsContainer } from './models/models-container';


export default function ProfileDashboard({selectedTab='Account'}) {

  const theme = useTheme();

  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

  const [selectedButton, setSelectedButton] = useState(selectedTab);

  const renderContent = (selectedButton) => {
    switch (selectedButton) {
      case 'Team Models':
        return <ProfileTeamModelsDisplay />
      case 'Player Models':
        return <ProfilePlayerModelsDisplay />
      case 'Account':
        return <AccountSettings />
      case 'Payments':
        return <AccountPayment />
      default:
        <h1>To Implement</h1>
    }
  }

  return (
    <Box sx={{ display: 'flex' }}>
        {largeScreen &&
          <ProfileDrawer selectedButton={selectedButton} setSelectedButton={setSelectedButton}/>
        }
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: largeScreen ? `calc(100vh - ${navbarHeight}px)` : '100%',
            overflow: 'auto',
          }}
        >
        <ResponsiveContainer maxWidth="lg">
          <Stack direction="column" justifyContent="center" alignItems="center">
              {renderContent(selectedButton)}
          </Stack>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
}

function ProfileTeamModelsDisplay(){return <ProfileModelsContainer modelType={"team"} />}
function ProfilePlayerModelsDisplay(){return <ProfileModelsContainer modelType={"player"} />}

