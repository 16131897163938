import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
    Box,
    DialogTitle,
    DialogContent,
    Stack,
    TextField
} from '@mui/material';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { formInputStyle } from '../../../style/forms';
import { sleep } from '../../../utils/general';

import LoadingButton from '@mui/lab/LoadingButton';
import {teamUpdateRequest } from '../../../hooks/api/team';
import { playerUpdateRequest } from '../../../hooks/api/player';
import { ResponsiveDialog } from '../../../components/responsive';
import { MAX_MODEL_NAME_LENGTH } from '../../../config/models';

export function ProfileModelRenamePopup({ open, setOpen, modelType, currentModelName, modelIndex, loading, setLoading }) {

    return (
        <>
            {open &&
                <ProfileModelRenamePopupContent open={open} setOpen={setOpen} modelType={modelType} 
                currentModelName={currentModelName} modelIndex={modelIndex} loading={loading} setLoading={setLoading} />
            }
        </>
    )
}

function ProfileModelRenamePopupContent({ open, setOpen, modelType, currentModelName, modelIndex, loading, setLoading }) {

    const [error, setError] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const theme = useTheme();

    const handleResponse = (response) => {
        setError(false);
        setDisabled(false);
        setOpen(false);
        sleep(2000); // Sleep to allow for changes to cache and database to take effect before reload
        setLoading(true);
    }
    const handleError = (error) => {
        setError(true);
        setDisabled(false);
    }

    const handleRename = (event) => {
        setDisabled(true);

        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let newModelName = data.get("modelName")
        if (newModelName === "") {
            setDisabled(false);
            return;
        }

        const params = {
            'index': modelIndex,
            'new_name': newModelName
        }

        if (modelType === 'team') {
            teamUpdateRequest(params,handleResponse,handleError);
        } else if (modelType === 'player') {
            playerUpdateRequest(params,handleResponse,handleError);
        }

    }

    return (
        <>
            <ResponsiveDialog
                onClose={handleClose}
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }}>
                    Rename Model
                </DialogTitle>
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.primary.main,
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <Box component="form" onSubmit={handleRename} noValidate sx={{ mt: 1 }}>
                        <Stack direction="row" spacing={1}>
                            <TextField
                                defaultValue={currentModelName}
                                margin="normal"
                                required
                                fullWidth
                                id="modelName"
                                label="Model Name"
                                name="modelName"
                                autoFocus
                                inputProps={{ style: formInputStyle(theme), maxLength: MAX_MODEL_NAME_LENGTH }}
                            />
                            <LoadingButton
                                type="submit"
                                disabled={disabled}
                                loading={disabled}
                                variant="contained"
                                sx={{
                                    animation: error ? 'shake 1s' : 'none',
                                    backgroundColor: error ? 'red' : 'default-color',
                                }}
                            >
                                Save
                            </LoadingButton>
                        </Stack>
                    </Box>

                </DialogContent>
            </ResponsiveDialog>
        </>
    );
}