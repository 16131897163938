import { useParams, Navigate } from "react-router-dom";
import CreatePlayerModel from '../create-model/pages/create-player-model';
import { player_leagues } from "../../config/leagues";

const PlayerModelWrapper = () => {
    const { target } = useParams();

    if (!player_leagues.includes(target)) {
        return <Navigate to="/error" />;
    }

    return <CreatePlayerModel target={target}/>;
};

export default PlayerModelWrapper;