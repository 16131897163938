import { useParams, Navigate } from "react-router-dom";
import CreateTeamModel from '../create-model/pages/create-team-model';
import { team_leagues } from "../../config/leagues";

const TeamModelWrapper = () => {
    const { target } = useParams();
    if (!team_leagues.includes(target)) {
        return <Navigate to="/error" />; 
    }

    return <CreateTeamModel target={target}/>;
};

export default TeamModelWrapper;