import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    Divider,
    Stack,
    Typography
} from '@mui/material';

import Carousel from 'react-material-ui-carousel';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import HelpIcon from '@mui/icons-material/Help';
import { PredictionResultsImage } from '../common/graphics';
import { ResponsiveDialog } from '../../../../components/responsive';

export function PredictionResultsTutorialPopupButton({content}) {

    const [open,setOpen] = useState(false);

    const theme = useTheme();

    return (
        <>
            <Button variant="text" size="medium" onClick={() => {setOpen(true)}}>
                <HelpIcon fontSize="large" sx={{color: theme.palette.primary.main}}/>
            </Button>
            {open &&
                <PredictionResultsTutorialPopupContent open={open} setOpen={setOpen} content={content}/>
            }
        </>
    )
}

const carouselPopupContentHeight = 632; // Looks random but is not

function PredictionResultsTutorialPopupContent({ open, setOpen, content }) {

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <ResponsiveDialog
                onClose={handleClose}
                open={open}
                sx={{ 
                    '& .MuiPaper-root': {
                        // The actual popup styling
                        width: {xs: '90%',md: 500},
                        backgroundColor: 'background.paper',
                        minHeight: carouselPopupContentHeight
                    },
                }} 
            >
                <DialogTitle sx={{ m: 0, p: 2 }}>
                    Predictions Tutorial
                </DialogTitle>
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: "primary.main"
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Divider orientation="horizontal" sx={{ borderBottomWidth: '2px' }} flexItem />
                <DialogContent>
                    {content}
                </DialogContent>
            </ResponsiveDialog>
        </>
    );
}

export function TutorialCarousel(props){

    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));
    
    return (
        <Carousel
            animation="slide"
            autoPlay={false}
            navButtonsAlwaysVisible={largeScreen}
            navButtonsAlwaysInvisible={!largeScreen}
            indicators={true}
            NextIcon={<ArrowForwardIosIcon />}
            PrevIcon={<ArrowBackIosNewIcon />}
        >
            {props.children}
        </Carousel>
    )
}



export function CarouselItem(props)
{
return (
    <Stack direction="column" alignItems="center" height={carouselPopupContentHeight - 100}>
        <Stack 
            direction = "column"
            spacing={1}
            sx={{width: {xs: '100%',md: '75%'}}}
        >
            <Typography gutterBottom={true} variant="h5">{props.item.name}</Typography>
            <Typography variant="body">{props.item.description}</Typography>
            <PredictionResultsImage src={props.item.imageSrc} height="auto" width="100%" alt={props.item.name}/>
        </Stack>
    </Stack>
)
}