import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Button,
    DialogContent,
    DialogTitle,
    Stack,
    Typography
} from '@mui/material';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { sleep } from '../../../utils/general';
import { teamDeleteRequest } from '../../../hooks/api/team';
import { playerDeleteRequest } from '../../../hooks/api/player';
import { ResponsiveDialog } from '../../../components/responsive';


export function DeleteModelPopup({ open, setOpen, modelType, modelIndex, loading, setLoading }) {

    return (
        <>
            {open &&
                <DeleteModelPopupContent open={open} setOpen={setOpen} modelType={modelType} modelIndex={modelIndex}
                    loading={loading} setLoading={setLoading} />
            }
        </>
    )
}


function DeleteModelPopupContent({ open, setOpen, modelType, modelIndex, loading, setLoading }) {
    const navigate = useNavigate();
    let selectedTab = modelType == 'team' ? 'Team Models' : 'Player Models';

    const [disabled, setDisabled] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleResponse = (response) => {
        setDisabled(false);
        setOpen(false);
        sleep(3000); // Sleep 3 seconds to allow for changes to cache and database to take effect before reload
        navigate(0,{'state':{'selectedTab':selectedTab}})
    }
    const handleError = (error) => {
        setDisabled(false);
    }

    const handleDelete = () => {
        setDisabled(true);
        const params = {
            'index': modelIndex
        }

        if (modelType === 'team'){
            teamDeleteRequest(params,handleResponse,handleError);
        } else if (modelType === 'player') {
            playerDeleteRequest(params,handleResponse,handleError);
        }
    }

    return (
        <>
            <ResponsiveDialog
                onClose={handleClose}
                open={open}
            >
                <DialogTitle>

                </DialogTitle>
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.primary.main,
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <Stack spacing={2} justifyContent="center" alignItems="center">
                        <Typography variant="h6">
                            Delete model from account?
                        </Typography>
                        <Button variant="contained" onClick={() => handleDelete()}
                            sx={{ backgroundColor: 'red' }}
                        >
                            Delete
                        </Button>
                    </Stack>
                </DialogContent>
            </ResponsiveDialog>
        </>
    );
}