import { useState } from 'react';
import {
  Card, 
  CardContent, 
  Divider, 
  Typography 
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { paymentCreateCustomerPortalRequest } from '../../../../hooks/api/payment';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

export function SubscriptionManagement(){

  const [loading,setLoading] = useState(false);

  const handleResponse = (response) => {
    if (response.status == 200) {
      window.open(response.data.url, "_self");
    }
    else {
      // Only stop loading animation if not unexpected response
      setLoading(false);
    }
    
  }
  const handleError = (error) => {
    setLoading(false);
  }
  const handleButtonClick = async () => {
    setLoading(true);
    paymentCreateCustomerPortalRequest(handleResponse,handleError);
  };

  return (
    <Card variant="outlined" sx={{p:2,pt:1}}>
      <CardContent>
        <Typography variant="h6">
          Manage Your Subscription
        </Typography>
        <Divider sx={{p: 1}} />
        <Typography variant="body1" paragraph sx={{pt: 1}}>
          Click below to be securely redirected to Stripe
        </Typography>
        <LoadingButton
          variant="contained"
          color="primary"
          loading={loading}
          disabled={loading}
          endIcon={<ArrowRightAltIcon />}
          onClick={handleButtonClick}
        >
          Customer Dashboard
        </LoadingButton>
      </CardContent>
    </Card>
  );
};
