
import { 
    Dialog,
    DialogTitle,
    DialogContent,
    Slide
}    
from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PricingContent from './pricing';

export default function PaymentOptionsPopup({ open, setOpen, unauthorized=false }) {

  return (
    <>
        {open ?
            (
                <>
                    <PaymentOptionsPopupContent 
                    open={open} 
                    setOpen={setOpen}
                    unauthorized={unauthorized} 
                    />
                </>
            ) :
            (
                <>
                </>
            )
        }
    </>
)
}

function PaymentOptionsPopupContent({ open, setOpen, unauthorized }) {

  const title = unauthorized ?
    "Upgrade your subscription to access this feature" :
    "Select a plan to start getting a statistical edge"
  ;

  return (
    <Dialog open={open} TransitionComponent={Slide} fullWidth={true} TransitionProps={{ direction: 'down' }} 
    sx={{ '& .MuiPaper-root': {width: '100%',height: '100%'} }} maxWidth="xl"
    >
      <DialogTitle sx={{fontSize: 34, fontWeight: 'medium',textAlign: 'center'}}>
        {title}
      </DialogTitle>
      <IconButton
          onClick={() => {setOpen(false)}}
          sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.primary.main,
          }}
      >
          <CloseIcon />
      </IconButton>
      <DialogContent>
        <PricingContent setOpen={setOpen} />
      </DialogContent>
    </Dialog>
  );
}

