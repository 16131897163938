import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
    Box,
    DialogTitle,
    DialogContent,
    Stack,
    TextField
} from '@mui/material';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { formInputStyle } from '../../../style/forms';

import LoadingButton from '@mui/lab/LoadingButton';
import { ErrorDisplay } from '../../../components/status';

import { CreateDefaultModelName } from '../../../utils/general';
import { teamSaveRequest } from '../../../hooks/api/team';
import { playerSaveRequest } from '../../../hooks/api/player';
import { ResponsiveDialog } from '../../../components/responsive';
import { MAX_MODEL_NAME_LENGTH } from '../../../config/models';

export function ModelSummarySavePopup({ open, setOpen, setModelIsSaved, league, modelType, position="", prop="" }) {

    return (
        <>
            {open &&
                <ModelSummarySavePopupContent open={open} setOpen={setOpen} 
                setModelIsSaved={setModelIsSaved} league={league} modelType={modelType}
                position={position} prop={prop} />
            
            }
        </>
    )
}


function ModelSummarySavePopupContent({ open, setOpen, setModelIsSaved, league, modelType, position, prop }) {

    const defaultModelName = CreateDefaultModelName(league,modelType,position,prop);

    const [error, setError] = useState(null);
    const [disabled, setDisabled] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const theme = useTheme();

    const handleSaveResponse = (response) => {
        setModelIsSaved(true);
        setError(null);
        setDisabled(false);
        setOpen(false);
    }
    const handleSaveError = (error) => {
        if (error.response){
            if (error.response.status === 401) {
                setError(error.response.data.message);
            }
            else if (error.response.status === 404) {
                setError("Login session or model has expired, try again")
            }
            
        } else {
            setError("Something went wrong");
        }
        
        setDisabled(false);
    }

    const handleSave = (event) => {
        setDisabled(true);

        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let modelName = data.get("modelName")
        if (modelName === "") {
            setDisabled(false);
            return;
        }

        const params = {
            "model_name": modelName
        }

        if (modelType === 'team') {
            teamSaveRequest(params,handleSaveResponse,handleSaveError);
        } else if (modelType === 'player') {
            playerSaveRequest(params,handleSaveResponse,handleSaveError);
        }

    }

    return (
        <>
            <ResponsiveDialog
                onClose={handleClose}
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }}>
                    Save Model
                </DialogTitle>
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.primary.main,
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <Box component="form" onSubmit={handleSave} noValidate sx={{ mt: 1 }}>
                        <Stack direction="column" spacing={2}>
                            <Stack direction="row" spacing={1}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="modelName"
                                    label="Model Name"
                                    name="modelName"
                                    autoFocus
                                    defaultValue={defaultModelName}
                                    inputProps={{ style: formInputStyle(theme), maxLength: MAX_MODEL_NAME_LENGTH }}
                                />
                                <LoadingButton
                                    type="submit"
                                    disabled={disabled}
                                    loading={disabled}
                                    variant="contained"
                                    sx={{
                                        animation: error ? 'shake 1s' : 'none',
                                        // backgroundColor: error ? 'red' : 'default-color',
                                    }}
                                >
                                    Save
                                </LoadingButton>
                            </Stack>
                        
                            {error && 
                                <ErrorDisplay error={error}>
                                    {error}
                                </ErrorDisplay>
                            }
                        </Stack>

                    </Box>

                </DialogContent>
            </ResponsiveDialog>
        </>
    );
}