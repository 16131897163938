
// Basic Component Imports
import { useParams } from 'react-router-dom';
import { Stack } from '@mui/material';

import { ResponsiveContainer } from '../../../components/responsive';
import { TeamStatSelection } from '../components/team/team-stat-selection-table';
import { CreateTeamModelFAQs } from '../components/team/faqs';
import { CreateTeamModelWalkthrough } from '../../../components/onboarding/walkthroughs';
import { CreateTeamModelHeader } from '../components/common/headers';

export default function CreateTeamModel() {
    const { target } = useParams();

    return (
        <>
            <ResponsiveContainer>
                <CreateTeamModelHeader league={target} />
                <Stack direction="row" justifyContent="flex-end">
                    <CreateTeamModelWalkthrough />
                </Stack>
                <TeamStatSelection league={target} />
                <CreateTeamModelFAQs />
            </ResponsiveContainer>
        </>
    );
}
