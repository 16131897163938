import { useLocation,useNavigate } from 'react-router-dom';

import { VerificationPageContent } from "../components/verification";
import { ResponsiveContainer } from "../../../components/responsive";

export default function VerifyPage(){

    const location = useLocation();
    const navigate = useNavigate();

    const query = new URLSearchParams(location.search);
    const token = query.get('token');

    if (!token) {
        navigate('/');
    }

    return (
        <ResponsiveContainer>
            <VerificationPageContent token={token} />
        </ResponsiveContainer>
    )
}