
import { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import VerificationInput from "react-verification-input";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

import {
    Button,
    Stack,
    Typography
} from '@mui/material';

import { ErrorDisplay, SuccessDisplay } from '../../../components/status';
import { sendVerificationEmailAndRedirect } from '../../../hooks/api-wrappers';
import { accountVerificationAttemptsRequest, accountVerifyPostRequest } from '../../../hooks/api/account';
import { useAuth } from '../../../hooks/auth';


const zeroRemainingAttemptsMsg = "Out of attempts, send new code to try again";

export function VerificationPageContent({token}){

    const navigate = useNavigate();

    const [error, setError] = useState(null);
    const [verificationSuccessful,setVerificationSuccessful] = useState(false);
    const [remainingAttempts,setRemainingAttempts] = useState(null);

    const handleSendNewCode = () => {
        sendVerificationEmailAndRedirect(navigate)
    }

    const handleResponse = (response) => {
        const local_remaining_attempts = response.data.remaining_attempts
        setRemainingAttempts(local_remaining_attempts);
        if (local_remaining_attempts === 0) {
            setError(zeroRemainingAttemptsMsg);
        }
    }
    const handleError = (error) => {}

    useEffect(()=>{
        // Request verification email
        const params = {
            token: token
        }
        accountVerificationAttemptsRequest(params,handleResponse,handleError);
    },[])

    return (
        <>
            <Stack direction="column" alignItems="center" width="100%" spacing={1}>

                <Typography variant="body1">
                    Check your email for a verification code
                </Typography>
                <Typography variant="caption" gutterBottom>
                    Code expires after 10 minutes
                </Typography>

                {remainingAttempts !== 0 && 
                <VerifyCodeInput 
                token={token} 
                setError={setError} 
                setVerificationSuccessful={setVerificationSuccessful}
                setRemainingAttempts={setRemainingAttempts}
                />
                }

                { remainingAttempts !== null && remainingAttempts !==0 && !verificationSuccessful &&
                <Typography variant="body">
                    {"Remaining attempts: "+remainingAttempts}
                </Typography>
                }

                {error && 
                    <ErrorDisplay error={error}>
                        {error}
                    </ErrorDisplay>
                }

                {verificationSuccessful &&
                    <SuccessDisplay 
                    message={"Account successfully verified, logging you in..."} 
                    Icon={VerifiedUserIcon}
                    />
                }

                <Button 
                onClick={handleSendNewCode}
                variant="contained" 
                sx={{textTransform: 'none'}}
                >
                    Send new code
                </Button>

            </Stack>
        </>
    )
}

function VerifyCodeInput({token,setError,setVerificationSuccessful,setRemainingAttempts}){

    const auth = useAuth();
    const navigate = useNavigate();

    const sleepAndGoHome = () => {
        // When verification is successful, show it then redirect
        setTimeout(() => {
          navigate('/')
        }, 2000);
    }

    const codeLength = 6

    const [code,setCode] = useState('');

    const handleChange = (newCode) => {

        // Clear errors if trying again
        if (newCode.length < codeLength) {
            setError(null);
        }

        setCode(newCode);
    }

    const handleResponse = (response) => {
        const local_remaining_attempts = response.data.remaining_attempts
        setRemainingAttempts(local_remaining_attempts);

        if (response.data.success) {
            setError(null);
            setVerificationSuccessful(true);
            auth.setAuthenticated(true);
            sleepAndGoHome();
        } else if (response.data.timed_out) {
            setError("Code timed out");
        }
        else if (local_remaining_attempts === 0) {
            setError(zeroRemainingAttemptsMsg);

        } else {
            setError("Incorrect code")
        }
    }
    const handleError = (error) => {
        if (error.response.data) {
            if (error.response.status === 400 || error.response.status === 404) {
                // Not logged in
                setError("Need to be logged in to verify account")
            }
        }
    }

    const handleComplete = (completeCode) => {
        
        const params = {
            token: token,
            code: completeCode
        }

        // API call to /verify (POST) with token and code
        // Authenticated if successful
        accountVerifyPostRequest(params,handleResponse,handleError);
    }

    return (
        <>
            <VerificationInput 
            value={code}
            length={codeLength}
            validChars={'0-9'}
            inputProps={{ 
                inputMode: "numeric", // Use numpad on mobile device
                autoComplete: "one-time-code" // Autofill from text message on iOS
            }}
            autoFocus={true}
            placeholder={"_"}
            onChange={handleChange}
            onComplete={handleComplete}
            />
        </>
    )
}