

export const descendingSortFunction = (a,b) => {

    // Nulls are always last
    if (a === null) {
        return 1;
    }
    
    // a is a real value and b is null, so a is first
    if (b === null) {
        return -1;
    }

    // a and b are real values, compare
    // A negative value indicates that a should come first
    // A positive value indicates that b should come first
    return b - a
}
export const ascendingSortFunction = (a,b) => {
    // Nulls are always last
    if (a === null) {
        return 1;
    }
    
    // a is a real value and b is null, so a is first
    if (b === null) {
        return -1;
    }

    // a and b are real values, compare
    // A negative value indicates that a should come first
    // A positive value indicates that b should come first
    return a - b
}