import { useLocation } from 'react-router-dom';

import ProfileDashboard from '../components/dashboard';

export default function Profile() {
  let location = useLocation();
  const selectedTab = location.state !== null ? location.state.selectedTab : 'Account';

  return (
    <>
      <ProfileDashboard selectedTab={selectedTab} />
    </>
  )
};