
import '../style/App.css';
import {
    Box, Typography, Grid, Button
} from "@mui/material";
import {Link} from 'react-router-dom';

export default function NoPage()
{
    return(
    <>
    <Grid container direction="column" alignItems="center" spacing={0} p={5}>
        <Box>
            <Typography variant="h4">You've Entered Unknown Territory...</Typography>
        </Box>
        <Box p={5}>
            <Link to='/' style={{ textDecoration: 'none', color: 'inherit' }}>
                <Button variant="contained">
                    Back To Safety
                </Button>
            </Link>
        </Box>
    </Grid>
    </>
    );
}