
import { 
    Stack
} from '@mui/material';

import {PaymentData} from './payment-data';
import {SubscriptionManagement} from './subscription-management'

export default function AccountPayment() {

    return (
        <>
            <Stack direction="column" spacing={2}>
                    <PaymentData />
                    <SubscriptionManagement />
             </Stack>
        </>
    );
};