import { useParams } from 'react-router-dom';

import { ResponsiveContainer } from '../../../components/responsive';
import { ModelSummaryDashboard } from '../components/dashboard';
import { ModelSummaryFAQs } from '../../../components/onboarding/faqs';

export default function ModelSummary(){
    const { target } = useParams();

    return (
        <>
            <ResponsiveContainer>
                <ModelSummaryDashboard modelType={target} />
                <ModelSummaryFAQs sx={{mt: 4}}/>
            </ResponsiveContainer>
        </>
    );
}

