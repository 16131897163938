

import { useNavigate } from 'react-router-dom';

import {
    Button,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Typography,
  } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';

import { ResponsiveDialog } from '../../../components/responsive';
import { SolvedSportsLogo } from '../../../components/graphics/solvedsports-logo';

export function ExpiredSubscriptionPopup({open,setOpen,expiredTrial}){

    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
    const handleClose = () => {
      setOpen(false);
    }

    const logoDims = {xs: 80, sm: 120};

    const dialogTitlePadding = {xs: 4, md: 8};

    const dialogContentPadding = {xs: 2, md: 4};

    const title = expiredTrial ? "Your trial has expired" : "Your subcription has expired";

    const buttonCopy = expiredTrial ? "Continue" : "Upgrade Subscription";

    const bodyCopy = expiredTrial ? 
        "Thank you for giving us a try. Click below to continue using Solved Sports."
        :
        "Your site access will be limited until you renew your subscription."
    ;
  
    return (
      <ResponsiveDialog open={open}>
        <IconButton
            onClick={handleClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'primary',
                zIndex: 2
            }}
        >
            <CloseIcon />
        </IconButton>
        <DialogTitle sx={{
            backgroundColor: theme.palette.background.default,pt:dialogTitlePadding,pb: dialogTitlePadding,
            // borderBottomRightRadius: 20,borderBottomLeftRadius: 20
            // backgroundImage: `linear-gradient(${theme.palette.background.default},
            // ${theme.palette.background.default},${theme.palette.background.default},
            // ${theme.palette.background.default},rgb(61,77,92))`
        }}
        >
            <Stack alignItems="center" justifyContent="center" width="100%" height="100%">
                <SolvedSportsLogo sx={{height:logoDims,width: logoDims}} />
            </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={3} alignItems="center" textAlign="center" sx={{width: '100%',p:dialogContentPadding}}>
            <Typography variant="h6">{title}</Typography>
            <Typography>
                {bodyCopy}
            </Typography>
            <Button
            onClick={() => {navigate('/pricing')}}
            variant="contained"
            size={isMobile ? "small" : "medium"}
            >
                {buttonCopy}
            </Button>
          </Stack>
        </DialogContent>
        
      </ResponsiveDialog>
    )
  };