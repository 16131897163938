
import { useNavigate } from 'react-router-dom';

import {
  Button,
  Stack,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export function ContentLock({open,opaque=false,zIndex=1}){

    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const lockDims = isMobile ? 30 : 60;

    return (
        <>
        {open &&
        <Stack direction="column" spacing={1} alignItems="center" justifyContent="center"
        sx={{ 
          height: '100%', width: '100%',
          color: '#fff', zIndex: zIndex,
          backdropFilter: opaque ? 'blur(6px)': 'blur(2px)',
          position: 'absolute', 
          margin: 'auto',
          textAlign: 'center', 
          left: 0, right: 0, bottom: 0, top: 0

          }}
        >
          <LockIcon sx={{width: lockDims, height: lockDims}} />
          <Button
          onClick={() => {navigate('/pricing')}}
          variant="contained"
          size={isMobile ? "small" : "medium"}
          >
            Upgrade Subscription
          </Button>
        </Stack>
        }
        </>
    )
}

export function BlurContent({zIndex=1,opaque=false}){

  return(
    <Stack direction="column" spacing={1} alignItems="center" justifyContent="center"
      sx={{ 
        height: '100%', width: '100%',
        color: '#fff', zIndex: zIndex,
        backdropFilter: opaque ? 'blur(6px)': 'blur(2px)',
        position: 'absolute', 
        margin: 'auto',
        textAlign: 'center', 
        left: 0, right: 0, bottom: 0, top: 0

        }}
    />
  )
}