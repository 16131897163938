import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbar,buildStyles} from 'react-circular-progressbar';
import { useTheme } from '@mui/material/styles';

import { perc2color } from '../utils/styling-support';

export function OverallGradeCircle({grade}){

    const theme = useTheme();

    // Convert decimal to 0-100 scale
    const grade_value = Math.round(grade * 100);

    const grade_based_color = perc2color(grade,theme);

    return (
        <CircularProgressbar 
        value={grade_value}
        text={`${grade_value}`}
        styles={buildStyles({

            textSize: theme.typography.h3.fontSize,
        
            // Colors
            pathColor: grade_based_color,
            textColor: grade_based_color,
            trailColor: theme.palette.missing.main,
        })}
        />
    )
}