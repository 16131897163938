import {useEffect} from 'react';
import { useNavigate, Outlet } from "react-router-dom";
import { useAuth } from "./auth";

const PrivateRoutes = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {

    if (!auth.loading && !auth.authenticated) {
      // If we have fetched the users authentication credentials
      // and they are not authenticated, redirect. But only do this if
      // we have finished fetching the credentials.
      navigate("/login")
    }
    
  }, [auth.loading]);

  return <Outlet />;
};

export default PrivateRoutes;