import { TutorialCarousel, CarouselItem, PredictionResultsTutorialPopupButton } from "../common/carousel-tutorial"

function PlayerPredictionsTutorialCarousel(props){

    var items = [
        {
            name: "Players and Predicted Prop",
            description: "Your model generates a predicted prop value for each player. \
                Those predicted props can be seen in the left most column of the table.",
            imageSrc: "PlayerPredictionResultsFirst.png"
        },
        {
            name: "Predicted Prop Total",
            description: "From the predicted prop, a predicted prop total can be calculated for each player.",
            imageSrc: "PlayerPredictionResultsSecond.png"
        },
        {
            name: "Prediction Value",
            description: "The difference between your model's predicted line and the sportsbooks' line represents the likelihood \
            for a winning bet. Differences that are large enough to have value are colored in green. These are the lines \
            your model recommends betting on.",
            imageSrc: "PlayerPredictionResultsThird.png"
        },
        {
            name: "Compare to Sportsbook Lines",
            description: "We show you the best odds among popular sportsbooks. You can also look at each sportsbook individually \
            if you only use a single site.",
            imageSrc: "PlayerPredictionResultsFourth.png"
        }
    ]
        
    
    return (
        <TutorialCarousel>
            {
                items.map( (item, i) => <CarouselItem key={i} item={item} /> )
            }
        </TutorialCarousel>
    )
}

export function PlayerPredictionResultsTutorialPopupButton(){

    return (
        <PredictionResultsTutorialPopupButton
        content={<PlayerPredictionsTutorialCarousel />}
        />
    )
}