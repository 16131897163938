
import { 
    Stack,
    Typography
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

// Every item in dashboard should be wrapped in this component to properly format
export function DashboardItem(props){

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

    const {title,tooltip,children,sx,...otherProps} = props;

    const sidePadding = isMobile ? 0.75 : 1;

    return (
        <Stack direction="column" spacing={1} className={"DashboardItem"}
        height="100%" maxHeight={largeScreen ? null : 400} width="100%"
        {...otherProps}
        sx={{backgroundColor: theme.palette.background.paper,borderRadius: 2, p: sidePadding,pt: 2,pb: 2,...sx}}>
            {title && 
            <Stack direction="row" alignItems="center" spacing={0.5}>
                <Typography variant="h6">{title}</Typography>
                {tooltip && tooltip}
            </Stack>
            }
            <Stack alignItems="center" justifyContent="center" height="100%" width="100%"
            // sx={{border: 1, borderColor: 'red'}} // Uncomment for debug
            >
                {children}
            </Stack>
        
        </Stack>
    )
}