import {useEffect,useState} from 'react';
import {
    Stack,
    Typography
} from '@mui/material';

import {useNavigate} from 'react-router-dom';
import dayjs from 'dayjs';

import { TeamPredictionResultsTable } from "./team-prediction-results-table";
import { TeamPredictionResultsTutorialPopupButton } from './tutorial';
import { teamPredictionsRequest } from '../../../../hooks/api/team';

export function TeamPredictionResultsContainer({league,date}) {

    const navigate = useNavigate();

    const [predictionsData,setPredictionsData] = useState([]);
    const [loading,setLoading] = useState(true);

    const handleResponse = (response) => {
      setPredictionsData(response.data);
      setLoading(false);
    }
    const handleError = (error) => {
      setLoading(false);
      if (error.response) {  
        // CSRF rejection = 400
        // Model not found in session = 404
        if (error.response.data.csrf || error.response.status === 404) {
            navigate('/');
        }
        
        // 500
        else {
            navigate('/');
        }
      }
      // No response
      else {
          navigate('/');
      }
    }

    useEffect(() => {
      const params = {
        'league' : league,
        'date'  : dayjs(date).format('YYYYMMDD')
      }
      teamPredictionsRequest(params,handleResponse,handleError);
      }, []);

    return (
        <>
          <Stack direction="column" justifyContent="center" alignItems="center" sx={{pb: 5}}>
            {loading &&
                <Typography variant="h5">
                  Generating predictions for {league} on {dayjs(date).format("MMM D, YYYY")}
                </Typography>
            }
            
            <Stack direction="row" justifyContent="flex-end" sx={{width: "100%"}}>
                <TeamPredictionResultsTutorialPopupButton />
            </Stack>
            <TeamPredictionResultsTable league={league} date={date} predictionData={predictionsData} loading={loading} />
          </Stack>
        </>
    )
}