import {
    Box,
    Stack,
    Typography
} from '@mui/material';

import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { FAQs } from "../../../../components/onboarding/faqs";

export function PredictionResultsFAQs(styleProps) {

    const BestBetMarkQuestion = (
        <Stack direction="row" alignItems="center" spacing={0.5}>
            <Box>What does</Box> 
            <MonetizationOnIcon color="success" /> 
            <Box>mean?</Box>
        </Stack>
    )

    const BestOddsAnswer = (
        <Stack direction="column" spacing={1}>
            <Typography>TLDR: The sportsbook with the best odds for that bet</Typography>
            <Typography>
            {"Both the line (i.e. -3.5) and price (i.e. -110) factor into the Best Odds. For totals, the lowest line will be the best bet for an over \
            and the highest line will be the best bet for an under. For spreads, the Best Odds will be whichever sportsbook is giving the \
            most points. For example, -2.5 < -1 < +1. If multiple sportsbooks have the same line, price will be used to determine which \
            is better. If one sportsbook is offering -3.5 at -110, and another is offering -3.5 and -112, the first sporsbook is chosen because \
            you would make more money on the same bet."}
            </Typography>
        </Stack>
    )

    var faqItems = [
        {
            "summary" : BestBetMarkQuestion,
            "details" : "This symbol marks the sportsbook(s) with the best odds for that row/bet."
        },
        {
            "summary" : 'How are the Best Odds determined?',
            "details" : BestOddsAnswer
        }
    ]

    return (
        <>
            <FAQs faqItems={faqItems} styleProps={styleProps} />
        </>
    )
}