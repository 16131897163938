import { Outlet } from "react-router-dom";
import { Navbar } from '../features/navigation/components/navbar';

export default function Layout() {

  return (
    <>
      <Navbar/>
      <Outlet />
    </>
  )
};