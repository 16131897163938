
import { ResponsiveContainer } from '../../../components/responsive';
import { JoinDiscord } from '../components/join-discord';


export default function Forum(){
    return (
        <>
            <ResponsiveContainer>
                <JoinDiscord />
                
            </ResponsiveContainer>
        </>
    );
}