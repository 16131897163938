import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';

import { teamStoreRequest,teamValidDatesRequest } from '../../../../hooks/api/team';
import { playerStoreRequest, playerValidDatesRequest } from '../../../../hooks/api/player';
import { PredictionsPopup } from './schedule';
import { TeamEventDisplay,PlayerEventDisplay } from './event-displays';

export function TeamsPredictionsPopup({ league, modelIndex, open, setOpen, setModelId = true }) {

  return (
    <>
      {/* Only render popup if opened, don't pre-render */}
      {open &&
          <TeamPredictionsPopupContent league={league} modelIndex={modelIndex} open={open} setOpen={setOpen} setModelId={setModelId} />
      }
    </>
  );
}

export function TeamPredictionsPopupContent({ league, modelIndex, open, setOpen, setModelId = true }) {


  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [datesWithEvents, setDatesWithEvents] = useState([]);
  const [contentLocked, setContentLocked] = useState(false);
  const [runDisabled, setRunDisabled] = useState(false);

  ////////////////////////////////////////////////////////
  // API calls
  ////////////////////////////////////////////////////////

  const navigate = useNavigate();

  const handleStoreResponse = (response) => {
    navigate('/team-prediction-results', { state: { 'league': league, 'date': selectedDate.toString() } });
  }
  const handleStoreError = (error) => {}

  const handleRunPredictions = () => {

    // date is Date object with US/NY timezones

    const params = {
      "index": modelIndex
    }

    // Set model id in session
    if (setModelId) {
      teamStoreRequest(params,handleStoreResponse,handleStoreError);
    }
    else {
      navigate('/team-prediction-results', { state: { 'league': league, 'date': selectedDate.toString() } });
    }


  };

    
  const handleValidDatesResponse = (response) => {
        setDatesWithEvents(response.data.dates);
    }

  const handleValidDatesError = (error) => {}

  const fetchValidDates = (dayjsObj) => {
      const params = {
          league: league,
          year: dayjsObj.year()
      }
      teamValidDatesRequest(params,handleValidDatesResponse,handleValidDatesError);
  }

  return (
    <>
      <PredictionsPopup
      title={`Select Date | ${league}`}
      open={open}
      setOpen={setOpen}
      selectedDate={selectedDate}
      setSelectedDate={setSelectedDate}
      runDisabled={runDisabled}
      contentLocked={contentLocked}
      handleRunPredictions={handleRunPredictions}
      datesWithEvents={datesWithEvents}
      fetchValidDates={fetchValidDates}

      EventDisplay={
        <TeamEventDisplay 
        league={league}
        selectedDate={selectedDate}
        setDisabled={setRunDisabled}
        contentLocked={contentLocked}
        setContentLocked={setContentLocked}
        />
      }
      
      />
    </>
  )
}

export function PlayerPredictionsPopup({ league, position, prop, modelIndex, open, setOpen, setModelId = true }) {

  return (
    <>
      {/* Only render popup if opened, don't pre-render */}
      {open &&
          <PlayerPredictionsPopupContent league={league} position={position} prop={prop}
            modelIndex={modelIndex} open={open} setOpen={setOpen} setModelId={setModelId} />
      }


    </>
  );
}

export function PlayerPredictionsPopupContent({ league, position, prop, modelIndex, open, setOpen, setModelId = true }) {

  const theme = useTheme();

  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [datesWithEvents, setDatesWithEvents] = useState([]);
  const [contentLocked, setContentLocked] = useState(false);
  const [runDisabled, setRunDisabled] = useState(false);

  ////////////////////////////////////////////////////////
  // API calls
  ////////////////////////////////////////////////////////

  const navigate = useNavigate();

  const handleStoreResponse = (response) => {
    navigate('/player-prediction-results', { state: { 'league': league, 'position': position,
      'prop': prop, 'date': selectedDate.toString() } });
  }
  const handleStoreError = (error) => {}

  const handleRunPredictions = () => {

    // date is Date object with US/NY timezone

    const params = {
      "index": modelIndex
    }

    // Set model id in session
    if (setModelId) {
      playerStoreRequest(params,handleStoreResponse,handleStoreError);
    }
    else {
      navigate('/player-prediction-results', { state: { 'league': league, 'position': position,
          'prop': prop, 'date': selectedDate.toString() } });
    }


  };

  const handleValidDatesResponse = (response) => {
    // Add to existing list of dates with events
    setDatesWithEvents(datesWithEvents.concat(response.data.dates));
  }

  const handleValidDatesError = (error) => {}

  const fetchValidDates = (dayjsObj) => {
    const params = {
        league: league,
        position: position,
        year: dayjsObj.year()
    }
    playerValidDatesRequest(params,handleValidDatesResponse,handleValidDatesError);
  }

  return (
    <>
      <PredictionsPopup
      title={`Select Date | ${league}`}
      open={open}
      setOpen={setOpen}
      selectedDate={selectedDate}
      setSelectedDate={setSelectedDate}
      runDisabled={runDisabled}
      contentLocked={contentLocked}
      handleRunPredictions={handleRunPredictions}
      datesWithEvents={datesWithEvents}
      fetchValidDates={fetchValidDates}

      EventDisplay={
        <PlayerEventDisplay 
        league={league}
        position={position}
        selectedDate={selectedDate}
        setDisabled={setRunDisabled}
        contentLocked={contentLocked}
        setContentLocked={setContentLocked}
        />
      }
      
      />
    </>
  )
}