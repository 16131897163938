import {
    Box,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText
  } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import CloseIcon from '@mui/icons-material/Close';
import AssessmentIcon from '@mui/icons-material/Assessment';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

import { ResponsiveDialog } from '../../../../components/responsive';


export function LoadingPopup({open,setOpen}){

    const theme = useTheme();
  
    const handleClose = () => {
      setOpen(false);
    }
  
    return (
      <ResponsiveDialog open={open}>
        <IconButton
            onClick={handleClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.primary.main,
                zIndex: 2
            }}
        >
            <CloseIcon />
        </IconButton>
        <DialogTitle>
            Complex Processing Behind the Scenes
        </DialogTitle>
        <DialogContent>
          <Box sx={{width: '100%'}}>
            <List>
              <ListItem>
                    <ListItemIcon>
                        <AssessmentIcon fontSize="large"/>
                    </ListItemIcon>
                    <ListItemText 
                    primary="Training Custom Machine Learning Model" 
                    secondary="A machine learning model is being trained just for you based on on tens of thousands 
                    of data points and the parameters you selected. Machine learning training takes time and is resource intensive."
                    />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                    <TrendingUpIcon fontSize="large"/>
                </ListItemIcon>
                <ListItemText 
                  primary="Calculating Historical Profitability" 
                  secondary="After the model is created, it is assessed for historical profitability on up to
                  10 years of historical results and odds."
                  />
              </ListItem>
            </List>
          </Box>
        </DialogContent>
        
      </ResponsiveDialog>
    )
  }