import chroma from 'chroma-js';

export function perc2color(percAsDecmial,theme) {

    const red = theme.palette.error.dark;
    const orange = theme.palette.warning.dark;
    const green = theme.palette.success.dark;

	let colorScale = chroma.scale([red,red,orange,orange,green]).classes(5);

    return colorScale(percAsDecmial).hex()
}