
import dayjs from 'dayjs';

// Delay in ms
// 3000 = 3 seconds
export const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

export const CreateDefaultModelName = (league,modelType,position,prop) => {

    const now = dayjs().format('MMDD');
    let modelName = "";

    if (modelType == 'team')
    {
        modelName = league + "_" + now;
    }
    else
    {
        modelName = position + "_" + prop + "_" + now;
    }

    return modelName;
}

export const uniqueValuesInDictList = (array,key) => {
    let map = new Map();
    array.forEach(value => map.set(value[key], value[key]));
    return [...map.values()];
};

export function ordinal_suffix_of(i) {
    var j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
}