// Basic Component Imports
import { useParams } from 'react-router-dom';
import {Stack} from '@mui/material';

import { ResponsiveContainer } from '../../../components/responsive';
import { PlayerStatSelection } from '../components/player/player-stat-selection-table';
import { CreatePlayerModelFAQs } from '../components/player/faqs';
import { CreatePlayerModelWalkthrough } from '../../../components/onboarding/walkthroughs';
import { CreatePlayerModelHeader } from '../components/common/headers';

export default function CreatePlayerModel() {
    const { target } = useParams();
    return (
        <>
            <ResponsiveContainer>
                <CreatePlayerModelHeader league={target} />
                <Stack direction="row" justifyContent="flex-end">
                    <CreatePlayerModelWalkthrough />
                </Stack>
                <PlayerStatSelection league={target} />
                <CreatePlayerModelFAQs />
            </ResponsiveContainer>
        </>
    );
}