
import { useEffect, useState } from "react";
import { useLocation,useNavigate } from "react-router-dom";
import { ResponsiveContainer } from '../../../components/responsive';
import { TeamPredictionResultsContainer } from "../components/team/team-prediction-results-container";
import { PredictionResultsFAQs } from "../components/common/faqs";

export default function TeamPredictionResults() {

    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;

    const [loading,setLoading] = useState(true);

    useEffect(() => {
        if ( state === null 
            || !state.league
            || !state.date
        ) {
            navigate('/')
        }
        setLoading(false);
    }, [loading]);

    return (
        <ResponsiveContainer>
            {!loading &&
            <TeamPredictionResultsContainer league={state.league} date={state.date} />
            }
            <PredictionResultsFAQs />
        </ResponsiveContainer>
    );
}