import { useLocation,useNavigate } from 'react-router-dom';
import { ResponsiveContainer } from '../../../components/responsive';
import { ResetPasswordForm } from '../components/reset-password';


export default function ResetPasswordPage() {

    const location = useLocation();
    const navigate = useNavigate();

    const query = new URLSearchParams(location.search);
    const token = query.get('token');

    if (!token) {
        navigate('/');
    }

    return (
        <ResponsiveContainer>
            <ResetPasswordForm token={token}/>
        </ResponsiveContainer>
    );
}