import { useState,useEffect } from 'react';
import {Box, Container} from '@mui/material';
import { useNavigate,useLocation } from 'react-router-dom';

import { Login } from '../components/user';
import { sendVerificationEmailAndRedirect } from '../../../hooks/api-wrappers';

import { useAuth } from '../../../hooks/auth';
import { accountAuthenticatedRequest, accountLoginRequest } from '../../../hooks/api/account';
import { paymentUpgradeSession } from '../../../hooks/api/payment';

export default function LoginPage() {

    const auth = useAuth();

    let location = useLocation();
    let price_lookup_key = location.state !== null ? location.state.price_lookup_key : null;

    const [disabled, setDisabled] = useState(false);
    const navigate = useNavigate();

    const [error, setError] = useState(null);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    
    const handleAuthenticatedResponse = (response) => {
        if (response.data.success) {
            navigate('/');
        }
    }
    const handleAuthenticatedError = (error) => {}

    // Confirm login
    useEffect(() => {
        accountAuthenticatedRequest(handleAuthenticatedResponse,handleAuthenticatedError);
    })

    const handleCreatePortalResponse = (response) => {
        window.open(response.data.url, "_self");
    }
    const handleCreatePortalError = (error) => {
        if (error.response){
            if (error.response.status === 409){
                navigate('/',{state : {subscription_conflict: true}})
            }
        }
    }

    const redirectToStripe = () => {
        const params = {
            "lookup_key": price_lookup_key
        }
        paymentUpgradeSession(params,handleCreatePortalResponse,handleCreatePortalError);
    }

    const handleLoginResponse = (response) => {
        // Redirect to stripe to setup subscription if requested
        // Free trial will never get here so only would be enrolling
        // in actual subscription here
        setError(null);
        auth.setAuthenticated(true);

        if (price_lookup_key != null) {
            redirectToStripe();
        } else {
            // Normal login, redirect to landing page
            navigate('/',
                {state : 
                    {
                        expired_subscription: response.data.expired_subscription,
                        expired_trial: response.data.expired_trial
                    }
                }
            )
        }
    }
    const handleLoginError = (error) => {
        if (error.response.status === 401) {
            sendVerificationEmailAndRedirect(navigate);
        }
        else if (error.response.data) {
            setError(error.response.data.message);
        }
        else {
            setError('Failed to login');
        }
        setSubmitDisabled(false);
    }

    const handleSubmit = (event,userData) => {
        if (userData.google_account === false) {
            if (submitDisabled) {
                return;
            }
            event.preventDefault();
        }

        const params = {
            email: userData.email,
            password: userData.password,
            google_account: userData.google_account
        }

        setSubmitDisabled(true);
        accountLoginRequest(params,handleLoginResponse,handleLoginError);
    };

    const handleSignup = () => {
        navigate('/register');
    }

    const handleForgotPassword = () => {
        navigate('/forgot-password');
    }

    return (
        <Container component="main" maxWidth="xs">
            <Box component="div" sx={{mt: 8}}>
                <Login handleSubmit={handleSubmit} handleSignupButton={handleSignup} handleForgotPasswordButton={handleForgotPassword} 
                    submitDisabled={submitDisabled} error={error} setError={setError} />
            </Box>
        </Container>
    );
}