
import {SportsFootball, SportsBasketball} from '@mui/icons-material';

export const team_leagues = [
    "NFL",
    "NBA",
    "NCAA-Football",
    "NCAA-Basketball"
]

export const player_leagues = [
    "NFL",
    "NBA"
]

export const league_config = [
    { id: 1, name: 'NFL', target: 'NFL', player_enabled: true},
    { id: 2, name: 'NBA', target: 'NBA', player_enabled: true},
    { id: 3, name: 'NCAA-Football', target: 'NCAA-Football', player_enabled: false},
    { id: 4, name: 'NCAA-Basketball', target: 'NCAA-Basketball', player_enabled: false}
];

export function LeagueIcon(props) {

    const {league,...iconSxProps} = props;

    const map = {
        "NFL": <SportsFootball {...iconSxProps} />,
        "NCAA-Football": <SportsFootball {...iconSxProps} />,
        "NBA": <SportsBasketball {...iconSxProps}/>,
        "NCAA-Basketball": <SportsBasketball {...iconSxProps} />
    }

    return map[league]
}

export const predictionWarningMonthsByLeague = {
    // Months are zero indexed so January = 0 to be used
    // to compared to dayjs().month()
    "NFL"               : [
        9
    ],
    "NBA"               : [
        10
    ],
    "NCAA-Football"     : [
        9
    ],
    "NCAA-Basketball"   : [
        10
    ]
}