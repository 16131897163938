import { useTheme } from '@mui/material/styles';
import { useState, useEffect } from 'react';

import {
    Avatar,
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    Skeleton,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { useNavigate } from "react-router-dom";
import dayjs from 'dayjs';

import { formInputStyle } from '../../../style/forms';
import { SuccessSnackbar } from '../../../components/snackbars';

import { accountDetailsRequest, accountUpdateRequest } from '../../../hooks/api/account';
import { useAuth } from '../../../hooks/auth';
import { TogglePasswordAdornment } from '../../../components/buttons';
import { MIN_PASSWORD_LENGTH, MAX_PASSWORD_LENGTH } from '../../../config/account';

export default function AccountSettings() {

    const auth = useAuth();
    const navigate = useNavigate();

    // Initial user data
    const theme = useTheme();
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState(
        {
            first: '',
            last: '',
            username: '',
            email: '',
            promotional: false,
            google_account: false,
            existing_password: '',
            new_password: '',
            new_password_confirmation: '',
            created: null
        }
    );
    const [isFormEditable, setIsFormEditable] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [isChecked, setIsChecked] = useState(false);
    const [error, setError] = useState(null);
    const [successfulChangeSnackbarOpen,setSuccessfulChangeSnackbarOpen] = useState(false);
    const [showPassword,setShowPassword] = useState(false);
    const [showNewPassword,setShowNewPassword] = useState(false);
    const [showConfirmationNewPassword,setShowConfirmationNewPassword] = useState(false);
    const [isPasswordLengthValid,setIsPasswordLengthValid] = useState(true); // Set to true to prevent it initially showing

    const [updateLoading,setUpdateLoading] = useState(false);

    const handleFormChange = (event) => {

        const field = event.target.id;
        let value = event.target.value;

        // Check if passwords match
        if (field === 'new_password') {
            setPasswordsMatch(value === formData.new_password_confirmation);
            setIsPasswordLengthValid(value.length >= MIN_PASSWORD_LENGTH);
        } else if (field === 'new_password_confirmation') {
            setPasswordsMatch(value === formData.new_password);
        }
        else if (field === 'promotional') {
            setIsChecked(event.target.checked);
            value = event.target.checked;
        }

        setFormData({ ...formData, [field]: value});
    };

    const handleEditButtonClick = () => {
        setIsFormEditable(!isFormEditable);
    };

    const sleepAndLogout = () => {
        setTimeout(() => {
            auth.logout(navigate,'/login');
          }, 2000);
    }

    const handleSubmitResponse = (response) => {
        setError(null);
        setIsFormEditable(false);
        setUpdateLoading(false);

        // Clear formData of passwords
        setFormData({ ...formData,
        existing_password: '',
        new_password: '',
        new_password_confirmation: ''
        });

        setSuccessfulChangeSnackbarOpen(true);

        // Logout if email was updated so that verification can occur on login
        if (response.data.email_updated){
            sleepAndLogout();
        }
    }
    const handleSubmitError = (error) => {
        if (error.response.data) {
            setError(error.response.data.message);
        }
        else {
            setError('Failed to make changes');
        }
        setUpdateLoading(false);
    }

    const handleSubmit = () => {
        setUpdateLoading(true);

        const params = {
            first: formData.first,
            last: formData.last,
            username: formData.username,
            email: formData.email,
            promotional: formData.promotional,
            existing_password: formData.existing_password,
            new_password: formData.new_password
        }
        
        accountUpdateRequest(params,handleSubmitResponse,handleSubmitError);
    };

    const handleDetailsResponse = (response) => {
        const userData = response.data.information;

        // Update the state with the fetched data
        setFormData({ ...formData,
            username: userData.username,
            email: userData.email,
            first: userData.first,
            last: userData.last,
            promotional: userData.promotional,
            google_account: userData.google_account,
            created: dayjs(new Date(userData.created).toLocaleString()).format("LLL")
        }
        );
        setIsChecked(userData.promotional);
        setLoading(false);

    }
    const handleDetailsError = (error) => {
        setLoading(false);
    }

    useEffect(() => {
        accountDetailsRequest(handleDetailsResponse,handleDetailsError);
    }, []);


    return (
        <>
            
            <Stack direction="column" alignItems="center" spacing={1}
                sx={{textAlign: "center",width: '100%', maxWidth: 500}}
            >
                {loading ? 
                        <LoadingFormDisplay />
                        :
                (<>
                <Typography variant="h6" gutterBottom>Started {formData.created}</Typography>

                {formData.google_account &&
                <>
                    <Stack 
                    direction="row" 
                    alignItems="center" 
                    spacing={0.2}
                    sx={{backgroundColor: '#f2f2f2', pb:0.5,pt:0.5,pl:1.5,pr:1.5, borderRadius: 2}}
                    >
                        <Typography variant="body1" gutterBottom sx={{color: 'black',pl:0.5}}>Signed in with</Typography>
                        <Avatar src={require('../../../assets/google-graphics/web_neutral_rd_na@3x.png')} 
                        sx={{height: 40,width: 40}}
                        />
                    </Stack>
                </>}

                <Box component="form" noValidate sx={{textAlign:"center",width:"100%"}}>
                    <Stack direction="column" alignItems="center" spacing={2} sx={{textAlign:"center",width:"100%"}}>

                        
                        <Stack direction="row" alignItems="space-between" spacing={{xs:1,sm:4}} sx={{width:"100%", pb: 1}}>
                                <Button fullWidth variant="contained" color="primary" onClick={handleEditButtonClick}>
                                    {isFormEditable ? 'Disable Editing' : 'Enable Editing'}
                                </Button>
                                <LoadingButton 
                                onClick={handleSubmit} 
                                fullWidth
                                loading={updateLoading}
                                disabled={!isFormEditable || !passwordsMatch || updateLoading} 
                                variant="contained" 
                                color="primary"
                                >
                                    Update
                                </LoadingButton>
                        </Stack>
                        {(formData.google_account && isFormEditable) &&
                        <Grid item xs={12} sx={{textAlign: 'center'}}>
                            <Typography variant="body" gutterBottom>
                                Account was created with Google, only certain fields can be updated.
                            </Typography>
                        </Grid>
                        }
                        {(!formData.google_account && isFormEditable) &&
                        <Grid item xs={12} sx={{textAlign: 'center'}}>
                            <Typography variant="body" gutterBottom>
                                If you update your email, you will be logged out and will have to 
                                verify your new email when you log back in
                            </Typography>
                        </Grid>
                        }
                        {error &&
                        <Grid item xs={12} sx={{textAlign: 'center'}}>
                            <Typography variant="body" color="error">
                                {error}
                            </Typography>
                        </Grid>
                        }
                            <TextField
                                autoComplete="given-name"
                                name="username"
                                required
                                fullWidth
                                id="username"
                                label="Username"
                                autoFocus
                                inputProps={{ style: formInputStyle(theme) }}
                                value={formData.username}
                                onChange={handleFormChange}
                                disabled={!isFormEditable}
                            />
                            <TextField
                                autoComplete="given-name"
                                name="email"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                autoFocus
                                inputProps={{ style: formInputStyle(theme) }}
                                value={formData.email}
                                onChange={handleFormChange}
                                disabled={!isFormEditable || formData.google_account}
                            />
                            <TextField
                                autoComplete="given-name"
                                name="firstName"
                                required
                                fullWidth
                                id="first"
                                label="First Name"
                                autoFocus
                                inputProps={{ style: formInputStyle(theme) }}
                                value={formData.first}
                                onChange={handleFormChange}
                                disabled={!isFormEditable || formData.google_account}
                            />
                            <TextField
                                required
                                fullWidth
                                id="last"
                                label="Last Name"
                                name="lastName"
                                autoComplete="family-name"
                                inputProps={{ style: formInputStyle(theme) }}
                                value={formData.last}
                                onChange={handleFormChange}
                                disabled={!isFormEditable || formData.google_account}
                            />
                            <FormControlLabel
                                id='checkbox'
                                name='check'
                                control={
                                    <Checkbox
                                        id='promotional'
                                        value={isChecked.toString()}
                                        checked={isChecked}
                                        color="primary"
                                        disabled={!isFormEditable}
                                        onChange={handleFormChange}
                                    />
                                }
                                sx={{ 
                                    '& .MuiFormControlLabel-label': { padding: 1 },
                                    textAlign: 'left'
                                }}
                                label="Receive inspirational, marketing promotions and updates via email."
                            />
                            {!formData.google_account &&
                            <>
                            <Typography variant="h5">Change Your Password</Typography>
                            <TextField
                                required
                                fullWidth
                                type={showPassword ? 'text' : 'password'}
                                id="existing_password"
                                label="Current Password"
                                name="existing_pw"
                                autoComplete="off"
                                inputProps={{ style: formInputStyle(theme) }}
                                value={formData.existing_password}
                                onChange={handleFormChange}
                                disabled={!isFormEditable}
                                InputProps = {
                                    {
                                      endAdornment: (
                                        <TogglePasswordAdornment 
                                        showPassword={showPassword}
                                        setShowPassword={setShowPassword}
                                        disabled={!isFormEditable}
                                        />
                                      )
                                    }
                                }
                            />
                            <TextField
                                required
                                fullWidth
                                type={showNewPassword ? 'text' : 'password'}
                                id="new_password"
                                label="New Password"
                                name="new_pw"
                                autoComplete="off"
                                inputProps={{ style: formInputStyle(theme) }}
                                value={formData.new_password}
                                onChange={handleFormChange}
                                disabled={!isFormEditable}
                                InputProps = {
                                    {
                                      endAdornment: (
                                        <TogglePasswordAdornment 
                                        showPassword={showNewPassword}
                                        setShowPassword={setShowNewPassword}
                                        disabled={!isFormEditable}
                                        />
                                      ),
                                      minLength: MIN_PASSWORD_LENGTH,
                                      maxLength: MAX_PASSWORD_LENGTH,
                                    }
                                }
                            />
                            <TextField
                                required
                                fullWidth
                                type={showConfirmationNewPassword ? 'text' : 'password'}
                                id="new_password_confirmation"
                                label="Confirm New Password"
                                name="check_new_pw"
                                autoComplete="off"
                                inputProps={{ style: formInputStyle(theme) }}
                                value={formData.new_password_confirmation}
                                onChange={handleFormChange}
                                disabled={!isFormEditable}
                                InputProps = {
                                    {
                                      endAdornment: (
                                        <TogglePasswordAdornment 
                                        showPassword={showConfirmationNewPassword}
                                        setShowPassword={setShowConfirmationNewPassword}
                                        disabled={!isFormEditable}
                                        />
                                      ),
                                      minLength: MIN_PASSWORD_LENGTH,
                                      maxLength: MAX_PASSWORD_LENGTH,
                                    }
                                }
                            />
                            {!isPasswordLengthValid && (
                                <Typography display="block" variant="body" color="error" >
                                    Password must be at least {MIN_PASSWORD_LENGTH} characters
                                </Typography>
                            )}
                            {!passwordsMatch && (
                                <Typography variant="body" color="error">
                                    Passwords do not match
                                </Typography>
                            )}
                            </>
                            }
                            
                    </Stack>
                </Box>

                </>)}
            </Stack>
            <SuccessSnackbar 
            open={successfulChangeSnackbarOpen} 
            setOpen={setSuccessfulChangeSnackbarOpen} 
            message={"Successfully updated account information"}
            />
        </>
    );
}; 


function LoadingFormDisplay(){

    // Create arbitrary list to iterate over
    // and create loading items
    const loadingItems = Array(4).fill(1);

    return (
        <>
        <Skeleton variant="rounded" height={40} sx={{pl: '30%'}}/>
        {loadingItems.map((item, index) => (
                <Skeleton
                key={index}
                variant="rounded"
                height={40}
                sx={{pl: '100%'}}
                />
        ))}
        
        </>
    )
}