import { useState } from 'react';

import { BarChart, Bar, LineChart, Line, ScatterChart, Scatter } from 'recharts';
import { XAxis, YAxis, Tooltip, Legend, ReferenceLine, ResponsiveContainer } from 'recharts';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme} from '@mui/material/styles';
import { Box,Tab,Tabs,Typography } from '@mui/material';

import { CustomTabPanel } from '../../../components/responsive';

export function ChartTabs(props) {
    
    const [value, setValue] = useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const typographyVariant = isMobile ? "caption" : "body";
  
    return (
        <>
            <Tabs value={value} onChange={handleChange} centered>
                    <Tab sx={{minWidth: 20,p:{xs:1}}} label={<Typography variant={typographyVariant}>Histogram</Typography>} value={0} />
                    <Tab sx={{minWidth: 20,p:{xs:1}}} label={<Typography variant={typographyVariant}>QQ Plot</Typography>} value={1} />
                    <Tab sx={{minWidth: 20,p:{xs:1}}} label={<Typography variant={typographyVariant}>Residuals</Typography>} value={2} />
                    <Tab sx={{minWidth: 20,p:{xs:1}}} label={<Typography variant={typographyVariant}>Loss</Typography>} value={3} />
            </Tabs>
            <Box sx={{height:'100%',width:'100%',pr:{xs:0},pl:{xs:0},pt:1 }}>
                <CustomTabPanel value={value} index={0} >
                    <ResidualsHistogram index={1}
                        residualsHistogram={props.modelPlottingData["residualsHistogram"]}
                    />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1} sx={{width:'100%',height:'90%'}}>
                    <QQPlot index={3}
                        qqData={props.modelPlottingData["qqData"]}
                    />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2} sx={{width:'100%',height:'90%'}}>
                    <StandardizedResidualsPlot index={2}
                        residuals={props.modelPlottingData["standardizedResiduals"]}
                    />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3} sx={{width:'100%',height:'90%'}}>
                    <LossChart
                        lossData={props.modelPlottingData["lossData"]}
                    />
                </CustomTabPanel>
            </Box>
            
        </>
    );
}


// Common Plotting Controls
const plotAxisColor = 'white';

const chartMargin = {
    top: 5,
    right: 30,
    left: 20,
    bottom: 20,
};

export function LossChart(props) {

    const theme = useTheme();

    return (
        <ResponsiveContainer>
            <LineChart
                data={props.lossData}
                margin={chartMargin}
            >
                <XAxis 
                    dataKey="epoch" 
                    stroke={plotAxisColor} 
                    tick={false} tickLine={{ stroke: plotAxisColor }} 
                />
                <YAxis 
                    stroke={plotAxisColor} 
                    label={{ value: 'Loss', angle: -90, position: 'insideLeft', stroke: plotAxisColor }} 
                    tick={{ fill: plotAxisColor }} tickLine={{ stroke: plotAxisColor }} 
                />
                <Tooltip />
                <Legend />
                <Line 
                    type="monotone" 
                    dataKey="mean_absolute_error" 
                    dot={false} 
                    stroke={theme.palette.primary.main} strokeWidth={3} 
                />
                <Line 
                    type="monotone" 
                    dataKey="validation_mean_absolute_error" 
                    dot={false} stroke={theme.palette.secondary.main}  strokeWidth={3}
                />
            </LineChart>
        </ResponsiveContainer>
    );

}

export function ResidualsHistogram(props) {

    const theme = useTheme();

    const minTicks = 5;
    const tickCount = Math.max(minTicks,Math.round(props.residualsHistogram.length/4));

    return (
        <ResponsiveContainer>
            <BarChart
                data={props.residualsHistogram}
                margin={chartMargin}
            >
                <XAxis 
                    dataKey="bin"
                    type="number"
                    label={{ value: 'Prediction Error', position: 'bottom', stroke: plotAxisColor }} 
                    stroke={plotAxisColor} tickLine={{ stroke: plotAxisColor }} 
                    tickCount={tickCount}
                />
                <YAxis 
                    stroke={plotAxisColor}
                    label={{ value: 'Count', angle: -90, position: 'insideLeft', stroke: plotAxisColor }} 
                    tick={{ fill: plotAxisColor }} tickLine={{ stroke: plotAxisColor }}
                />
                <Tooltip />
                <Legend />
                <Bar 
                    dataKey="count" 
                    legendType='none' 
                    fill={theme.palette.primary.main} 
                />
            </BarChart>
        </ResponsiveContainer>
    );
}

export function StandardizedResidualsPlot(props) {

    const theme = useTheme();

    return (
        <ResponsiveContainer>
            <ScatterChart
                margin={chartMargin}
            >
                <XAxis
                    dataKey="score"
                    type='number' 
                    domain={['dataMin - 1','dataMax + 1']} 
                    label={{ value: 'Score', position: 'bottom', stroke: plotAxisColor }} 
                    stroke={plotAxisColor} tickLine={{ stroke: plotAxisColor }} 
                    tickCount={10}
                />
                <YAxis 
                    dataKey="residual" 
                    label={{ value: 'Residual Error', angle: -90, position: 'insideLeft', stroke: plotAxisColor }} 
                    stroke={plotAxisColor} 
                    tick={{ fill: plotAxisColor }} tickLine={{ stroke: plotAxisColor }} 
                />
                <Tooltip />
                <Legend />
                <Scatter
                    data={props.residuals} 
                    legendType='none' 
                    fill={theme.palette.primary.main}
                />
            </ScatterChart>
        </ResponsiveContainer>
    );

}

export function QQPlot(props) {

    const theme = useTheme();

    let actuals = props.qqData.map(item => item.actual);

    // Establish min and max values to be used for reference line and
    // axis limits to get the same a and y axis ticks.
    const minX = Math.min(...actuals) - 1;
    const maxX = Math.max(...actuals) + 1;

    return (
        <ResponsiveContainer>
            <ScatterChart
                margin={chartMargin}
            >
                <XAxis 
                    dataKey="actual" 
                    type="number" 
                    domain={[minX,maxX]} 
                    label={{ value: 'Actual Score', position: 'bottom', stroke: plotAxisColor }} 
                    stroke={plotAxisColor} tickLine={{ stroke: plotAxisColor }}
                    tickCount={10}
                />
                <YAxis 
                    dataKey="predicted" 
                    domain={[minX,maxX]} 
                    label={{ value: 'Predcted Score' , angle: -90, position: 'insideLeft', stroke: plotAxisColor }} 
                    stroke={plotAxisColor} 
                    tick={{ fill: plotAxisColor }} tickLine={{ stroke: plotAxisColor }} 
                />
                <Tooltip />
                <Legend />
                <Scatter 
                    data={props.qqData} 
                    legendType='none' 
                    fill={theme.palette.primary.main} 
                />
                <ReferenceLine 
                    segment={[{ x: minX, y: minX }, { x: maxX, y: maxX }]} 
                    stroke={theme.palette.secondary.main} strokeWidth={3} strokeDasharray="3 3" 
                />
            </ScatterChart>
        </ResponsiveContainer>
    );
}