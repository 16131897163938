import {
    Box,
    Button,
    Stack,
    Typography 
} from '@mui/material';

import { discordLink } from '../../../config/forum';
import { SolvedSportsLogo } from '../../../components/graphics/solvedsports-logo';

const images = require.context('../../../assets/socials', false, /\.png$/);

export function JoinDiscord(){

    const logoDims = {xs: 60,md:80};

    const LogoDivider = (
        <Typography variant="h5">X</Typography>
    )

    return (
        <Stack direction="column" width="100%" alignItems="center" justifyContent="center" textAlign="center" spacing={2}>

            <Stack direction="row" spacing={3} divider={LogoDivider} justifyContent="center" alignItems="center">
                <DiscordLogo sx={{height: logoDims,width: logoDims, borderRadius: {xs:2,md:4} }}/>
                <SolvedSportsLogo sx={{height: logoDims,width: logoDims}}/>
            </Stack>

            <Typography variant="h4">Join our Discord community</Typography>
            <Button target="_blank" href={discordLink}>
                Join
            </Button>
        </Stack>
    )
}

function DiscordLogo(props){
    let logoSrc;
    try {
        logoSrc = images('./discord-logo.png');
    }
    catch {
        logoSrc = null;
    }

    return (
        <Box
            component="img"
            alt="logo"
            src={logoSrc}
            {...props}
        />
    )
}