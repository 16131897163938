import {useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Card,
    CardContent,
    Typography,
    Grid,
    Stack,
    ToggleButton,
    ToggleButtonGroup
}    
from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import useMediaQuery from '@mui/material/useMediaQuery';

import { FeatureSummary } from '../../../components/feauture-summary';
import { paymentUpgradeSession, paymentSubscriptionRequest } from '../../../hooks/api/payment';
import { 
  freeFeatures,
  proFeatures,
  proComingSoonFeatures,
  premiumFeatures ,
  premiumComingSoonFeatures
} from '../../../config/features';

import { useAuth } from '../../../hooks/auth';
import { CancelTrialPopup } from './cancel-trial-popup';

export default function PricingContent({setOpen}){

    const auth = useAuth();

    // setOpen used when child of popup but can be unused

    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

    const [currentSubscriptionTier,setCurrentSubscriptionTier] = useState("");
    const [currentSubscriptionStatus,setCurrentSubscriptionStatus] = useState("");
    
    // Options: ['monthly','annual']
    const [priceType,setPriceType] = useState("monthly");

    const handleResponse = (response) => {
      setCurrentSubscriptionStatus(response.data.status);
      setCurrentSubscriptionTier(response.data.tier);
    }
    const handleError = (error) => {}

    useEffect(() => {      
        if (auth.authenticated) {      
            paymentSubscriptionRequest(handleResponse,handleError);
        }
        
    }, [auth.authenticated]);

    return (
        <Grid container spacing={2} direction={largeScreen ? "row":"column"}>
            <Grid item container xs={12} 
            width='100%' direction='row' justifyContent='center' alignItems="center">
              <ToggleButtonGroup
                color="primary"
                value={priceType}
                exclusive
                onChange={(event,newValue) => setPriceType(newValue)}
                size="large"
                aria-label="price-type-toggle"
                sx={{
                  '& .MuiButtonBase-root': {textTransform: 'none',pt: 1,pb: 1, pl: 4,pr: 4}
                }}
              >
                <ToggleButton value="monthly">Monthly</ToggleButton>
                <ToggleButton value="annual">Annual</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={4}>
              <PricingCard 
              tier={"Free"}
              priceType={priceType}
              monthlyPrice={0}
              annualPrice={0}
              features={freeFeatures}
              comingSoonFeatures={[]}
              product={"Free Trial"}
              setOpen={setOpen}
              disableButton={auth.authenticated}
              currentSubscription={currentSubscriptionStatus === 'trialing'}
              currentSubscriptionStatus={currentSubscriptionStatus}
              />
            </Grid>

            <Grid item xs={4}>
              <PricingCard 
              tier={"Pro"}
              priceType={priceType}
              monthlyPrice={10}
              annualPrice={85}
              features={proFeatures}
              comingSoonFeatures={proComingSoonFeatures}
              product={priceType === 'annual' ? "pro_annual" : "pro_monthly"}
              setOpen={setOpen}
              currentSubscription={currentSubscriptionTier === "PRO" && currentSubscriptionStatus === 'active'}
              currentSubscriptionStatus={currentSubscriptionStatus}
              />
            </Grid>

            <Grid item xs={4}>
              <PricingCard 
              tier={"Premium"}
              priceType={priceType}
              monthlyPrice={20}
              annualPrice={180}
              features={premiumFeatures}
              comingSoonFeatures={premiumComingSoonFeatures}
              product={priceType === 'annual' ? "premium_annual" :"premium_monthly"}
              setOpen={setOpen}
              currentSubscription={currentSubscriptionTier === "PREMIUM" && currentSubscriptionStatus === 'active'}
              currentSubscriptionStatus={currentSubscriptionStatus}
              />
            </Grid>
        </Grid>
    )
}

function PricingCard(
  {tier,priceType,monthlyPrice,annualPrice,features,comingSoonFeatures,product,setOpen,currentSubscriptionStatus,currentSubscription,disableButton=false})
{
  // tier - Does not get used in processing, just used as the name at the top of the Card
  // product - Gets used in processing.
  // currentSubscription (bool): True if this pricing card is for the subscription the customer currently holds
  // currentSubscriptionStatus (str): The Stripe status of the subscription
  
  const navigate = useNavigate();

  const [loading,setLoading] = useState(false);
  const [cancelTrialPopupOpen,setCancelTrialPopupOpen] = useState(false);

  const handleResponse = (response) => {
    if (response.status === 200) {
      window.open(response.data.url, "_self");

      // Close popup if it exists
      if (setOpen) {
        setOpen(false);
        setLoading(false);
      }
    }
    else {
      // Only stop loading animation if not unexpected response
      setLoading(false);
    }
    
  }

  const handleError = (error) => {
    if (error.response){
      // User is not logged in. Direct to sign up if free trial clicked and direct
      // to login if membership was clicked.
      if (error.response.data.csrf){
        if (product === "Free Trial"){
          navigate('/register');
        }
        else {
          navigate('/login', { state: { "price_lookup_key": product} });
        }
      }
      setLoading(false);
    }
    // Close popup if it exists
    if (setOpen) {setOpen(false);}
  }

  const handleOptionSelect = () => {
    // If trialing, use cancel and enroll popup rather than
    // a redirect to Stripe
    if (currentSubscriptionStatus === 'trialing') {
      setCancelTrialPopupOpen(true);
    }
    else {

      // Not trialing, can directly upgrade
      setLoading(true);

      const params = {
        "lookup_key": product
      }

      // This request will get rejected via CSRF if not logged in and handleError
      // will redirect to the proper place based on which product is selected
      paymentUpgradeSession(params,handleResponse,handleError);
    }
    };

  return (
    <>
      <Card sx={{height: '100%'}}>
        <CardContent sx={{height: '100%'}}>
        <Stack direction="column" spacing={1} sx={{height: "100%", pl: 2, pr: 2}}>
          <Box sx={{ p: 1, minHeight:'0',flexGrow: 1, textAlign: 'center'}}>
              <Typography variant="h4" gutterBottom>{tier}</Typography>
              <Box sx={{mb: 1}}>
                {priceType === 'annual' ?
                <>
                <Typography variant="h4" component="span">${annualPrice}</Typography>
                <Typography variant="subtitle" component="span">/Year</Typography>
                </>
                :
                <>
                <Typography variant="h4" component="span">${monthlyPrice}</Typography>
                <Typography variant="subtitle" component="span">/Month</Typography>
                </>
              }
              </Box>
              <LoadingButton 
              fullWidth
              loading={loading}
              disabled={disableButton || currentSubscription || loading}
              variant="contained" 
              color="primary" 
              onClick={() => handleOptionSelect()}
              sx={{mt:1}}
              >
                {currentSubscription ? "Current Subscription" : "Start "+tier}
              </LoadingButton>
              <FeatureSummary features={features} comingSoonFeautures={comingSoonFeatures} />
          </Box>
        </Stack>
        </CardContent>
      </Card>
      {cancelTrialPopupOpen && <CancelTrialPopup open={cancelTrialPopupOpen} setOpen={setCancelTrialPopupOpen} productLookupKey={product} />}
    </>
  )
}