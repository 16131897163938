import { PageHeader } from '../../../../components/page-header';

const subtitle = 'We do all of the work of organizing data for machine learning \
        and just allow you to select the stats you want your model to use';

export function CreateTeamModelHeader({league}){

    return (
        <PageHeader 
        title={`${league} Team Model`}
        subtitle={subtitle}
        />
    )
}

export function CreatePlayerModelHeader({league}){

    return (
        <PageHeader 
        title={`${league} Player Prop Model`}
        subtitle={subtitle}
        />
    )
}

