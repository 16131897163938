import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Card, 
    CardContent, 
    Divider,
    Typography, 
    Skeleton,
    Stack
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import dayjs from 'dayjs';
import { paymentSubscriptionRequest } from '../../../../hooks/api/payment';

import { tierComingSoonFeatureMap, tierFeatureMap } from '../../../../config/features';
import { FeatureSummary } from '../../../../components/feauture-summary';


function AccountSubscription ({ subscriptionStatus, subscriptionCreated, subscriptionPeriodEnd, subscriptionTier }) {
    const validSubscription = subscriptionStatus === 'active' || subscriptionStatus === 'trialing';
    const buttonColor =  validSubscription ? 'success' : 'error';
    const subscriptionSummary = validSubscription ? subscriptionTier : 'EXPIRED';

    const upgradeAvailable = !validSubscription || (subscriptionTier !== 'PREMIUM');

    const startDate = dayjs(new Date(subscriptionCreated * 1000)).format('MMM Do, YYYY');
    const periodEndDate = dayjs(new Date(subscriptionPeriodEnd * 1000)).format('MMM Do, YYYY');
    return (
        <Box justifyContent = "center" sx={{p: 1, pb:0}}>
            <Typography variant="h6" component="h1">
                Account Subscription Details
            </Typography>
            <Divider sx={{p: 1}} />
            <Stack spacing={2} alignItems="flex-start" sx={{pt: 2}}>
                <Stack direction="row" spacing={1}>
                <Button
                    variant="contained"
                    color={buttonColor}
                    sx={{
                        p: 1
                    }}
                    disableRipple
                    >
                    {subscriptionSummary}
                </Button>
                </Stack>
                <Typography variant='body1'>
                    Started: {startDate}
                </Typography>
                {subscriptionStatus === 'trialing' &&
                <Typography variant='body1'>
                    Trial ends on {periodEndDate}
                </Typography>
                }

                <UpgradeSubscriptionButton upgradeAvailable={upgradeAvailable} />

                <FeatureAccordion tier={subscriptionTier} />
            </Stack>
            
        </Box>
    )
}

function FeatureAccordion({tier}){
    // Only show this if we were able to find the user's tier to prevent 
    // unknowns

    const features = tierFeatureMap[tier];
    const comingSoonFeatures = tierComingSoonFeatureMap[tier] ? tierComingSoonFeatureMap[tier] : [];

    return (
        <>
        {features &&
        <Box>
            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                >
                Subscription Features
                </AccordionSummary>
                <Divider orientation="horizontal" flexItem />
                <AccordionDetails>
                    <FeatureSummary features={features} comingSoonFeautures={comingSoonFeatures} />
                </AccordionDetails>
            </Accordion>
        </Box>
        }
        </>
    )
}

export function PaymentData(){
    const [subscriptionStatus, setSubscriptionStatus] = useState("");
    const [subscriptionPeriodEnd, setSubscriptionPeriodEnd] = useState("");
    const [subscriptionTier, setSubscriptionTier] = useState("");
    const [subscriptionCreated, setSubscriptionCreated] = useState("");
    const [loading, setLoading] = useState(true);

    const handleResponse = (response) => {
        setSubscriptionStatus(response.data.status);
        setSubscriptionPeriodEnd(response.data.current_period_end)
        setSubscriptionTier(response.data.tier);
        setSubscriptionCreated(response.data.created);
        setLoading(false);
    }

    const handleError = (error) => {
        setLoading(false);
    }

    useEffect(() => {
        paymentSubscriptionRequest(handleResponse,handleError);
    }, []);

    

    return (
        <Card>
            {
                loading ? ( 
                    <>
                        <Skeleton variant="rectangular" height={200} /> 
                    </>
                ) : 
                (
                    <>
                        <Card variant="outlined" sx={{p:2,pt:1}}>
                        <CardContent>
                            <AccountSubscription 
                                subscriptionStatus={subscriptionStatus}  
                                subscriptionCreated={subscriptionCreated}
                                subscriptionPeriodEnd={subscriptionPeriodEnd} 
                                subscriptionTier={subscriptionTier}
                            />
                        </CardContent>
                        </Card>
                    </> 
                )} 
  
        </Card>
    );
}

function UpgradeSubscriptionButton({upgradeAvailable}){

    const navigate = useNavigate();

    return (
        <>
        {upgradeAvailable &&
        <Button 
        variant="contained"
        onClick={() => {navigate('/pricing')}}
        >
            Upgrade Subscription
        </Button>
        }
        </>
    )
}
