
import { FlaskClient } from "./setup";

export const paymentSubscriptionRequest = (handleResponse,handleError) => {
    FlaskClient.get('payment/subscription',
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const paymentCreateCustomerPortalRequest = (handleResponse,handleError) => {
    FlaskClient.get('payment/create-customer-portal-session',
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const paymentRefreshRequest = (handleResponse,handleError) => {
    FlaskClient.put('payment/refresh'
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const paymentUpgradeSession = (params,handleResponse,handleError) => {
    FlaskClient.get('payment/upgrade-session',
    {
        params: params
    }
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const trialUpgrade = (params,handleResponse,handleError) => {
    FlaskClient.delete('payment/trial-upgrade',
    {
    params: params
    }
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}