import { useGoogleLogin } from '@react-oauth/google';
import { Avatar, Button } from '@mui/material';
import { googleUserInfoRequest } from '../../../hooks/googleapis';

export function GoogleLogin({handleSubmit,setError}) {

    const handleResponse = (response) => {
        const googleAccountData = response.data;
        const userData = {
            email: googleAccountData.email,
            password: '', // Doesn't matter
            google_account: true
        }
        handleSubmit(null,userData);
    }

    const handleError = (error) => {
        setError("Something went wrong, failed to acquire google account information");

    }

    const handleSuccess = (credentialResponse) => {
        const access_token = credentialResponse.access_token;
        const params = {
            access_token: access_token
        }
        // Make API request to get user data
        googleUserInfoRequest(params,handleResponse,handleError);
    };

    const login = useGoogleLogin({
        // scope: [
        //   "openid",
        //   "https://www.googleapis.com/auth/userinfo.profile",
        //   "https://www.googleapis.com/auth/userinfo.email",
        // ].join(" "),
      
        onSuccess: handleSuccess,
        onError: handleError,
        flow: 'implicit' //default
      })

    return (
        <GoogleButton
        login={login}
        text={'Sign in with Google'}
        />
    )
}

export function GoogleSignUp({handleSubmit,setError}) {

    const handleResponse = (response) => {
        const googleAccountData = response.data;
        const userData = {
            first: googleAccountData.given_name,
            last: googleAccountData.family_name ? googleAccountData.family_name : '',
            username: googleAccountData.name,
            email: googleAccountData.email,
            password: '', // Doesn't matter
            passwordConfirmation: 'dsdfvdfbsdfbbdsbfdsf', // Doesn't matter
            promotional: true,
            google_account: true,
            recaptcha_response: ''
        }
        handleSubmit(null,userData);
    }
    const handleError = () => {
        setError("Failed to sign up with Google");
    };

    const handleSuccess = (credentialResponse) => {
        const access_token = credentialResponse.access_token;

        // Make API request to get user data
        const params = {
            access_token: access_token
        }
        // Make API request to get user data
        googleUserInfoRequest(params,handleResponse,handleError);
    };
    

    const login = useGoogleLogin({
        // scope: [
        //   "openid",
        //   "https://www.googleapis.com/auth/userinfo.profile",
        //   "https://www.googleapis.com/auth/userinfo.email",
        // ].join(" "),
      
        onSuccess: handleSuccess,
        onError: handleError,
        flow: 'implicit' //default
      })

    return (
        <GoogleButton
        login={login}
        text={'Sign up with Google'}
        />
    )
}

function GoogleButton({login,text}){
    // Remember login and sign up are identical from google's perspective

    return(
        <Button onClick={login} sx={{
            width: '100%',
            backgroundColor: '#f2f2f2',color: 'black', textTransform: 'none',
            '&.MuiButton-root:hover':{bgcolor: '#f2f2f2'}
            
        }}
        startIcon={<Avatar src={require('../../../assets/google-graphics/web_neutral_rd_na@3x.png')} />}
        >
            {text}
        </Button>
    )
}