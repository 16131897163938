
import ReCAPTCHA from "react-google-recaptcha";


export default function ReCaptchaButton({handleReCaptcha}){

    return (
        <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            size="normal"
            theme="dark"
            onChange={handleReCaptcha}
        />
    )
}