
import { accountVerifyGetRequest } from './api/account';

export const sendVerificationEmailAndRedirect = (navigate) => {

    const handleResponse = (response) => {
        const token = response.data.token;
        if (!response.data.verified) {
            navigate(`/verify?token=${token}`);
            navigate(0);
        } else {
            // Already verified, go to home
            navigate('/');
        }  
    }

    const handleError = (error) => {}

    // Request verification email
    accountVerifyGetRequest(handleResponse,handleError);
    
}