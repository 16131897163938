import axios from 'axios';

const GoogleAuthClient = axios.create({
    baseURL: 'https://www.googleapis.com/oauth2/v3',
    // timeout: 10000, // ms
    headers: {
        'Content-Type': 'application/json'
    }
  });

export const googleUserInfoRequest = (params,handleResponse,handleError) => {
    GoogleAuthClient.get('userinfo',
      {
        params: params
      }
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}