
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ReactGA from 'react-ga4';
import ErrorBoundary from './error-boundary';

import AuthProvider from '../hooks/auth';

import themeDark from '../style/theme';
import RouteProvider from './routes';

function App() {
  ReactGA.initialize('G-8XB4XKKWWW'); // Initialize Google Analytics Tag

  return (
    <>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}>
      <AuthProvider>
        <ThemeProvider theme = {themeDark}>
          <CssBaseline />
            <ErrorBoundary>
              <RouteProvider />
            </ErrorBoundary>
        </ThemeProvider>
      </AuthProvider>
    </GoogleOAuthProvider>
    </>
  );
}

export default App;
