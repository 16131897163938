import {useState} from 'react';
import {Box,Container} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Signup } from '../components/user';
import { sendVerificationEmailAndRedirect } from '../../../hooks/api-wrappers';

import { useAuth } from '../../../hooks/auth';
import { accountRegisterRequest } from '../../../hooks/api/account';

export default function SignupPage() {

    const auth = useAuth();

    const navigate = useNavigate();

    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [error, setError] = useState(null);

    const handleResponse = (response) => {
        // Should never occur because we always require email verification
        // on signup
        auth.setAuthenticated(true);
        navigate('/');
    }
    const handleError = (error) => {
        if (error.response.status === 401) {
            // Expected flow is to get here on signup. 401 status indicates that a user
            // needs to verify their account
            
            sendVerificationEmailAndRedirect(navigate);
            // Don't set submit disabled to false here, going to
            // redirect before loading animation stops
        }
        else if (error.response.data) {
            // Possible errors:
            // - Email or username is not unique
            // - ReCaptcha validation failed on server-side
            setError(error.response.data.message);
            setSubmitDisabled(false);
        }
        else {
            setError('Failed to signup');
            setSubmitDisabled(false);
        }
        
    }

    const handleSubmit = (event,userData) => {
        if (userData.google_account === false) {
            if (submitDisabled) {
                return;
            }
            event.preventDefault();
        }
        setSubmitDisabled(true);
        
        const params = {
            first: userData.first,
            last: userData.last,
            username: userData.username,
            email: userData.email,
            password: userData.password,
            promotional: userData.promotional,
            google_account: userData.google_account,
            recaptcha_response: userData.recaptcha_response
        }
        accountRegisterRequest(params,handleResponse,handleError);
    };

    const handleSignInButton = () => {
        navigate('/login');
    }
    return (
        <Container component="main" maxWidth="xs">
            <Box component="div" sx={{mt: 8,mb:8}}>
                <Signup handleSubmit={handleSubmit} handleSignInButton={handleSignInButton} 
                submitDisabled={submitDisabled} error={error} setError={setError} />
            </Box>
        </Container>
    );
}