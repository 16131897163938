import  {useState } from 'react';
import "react-circular-progressbar/dist/styles.css";

import {
    Box,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    IconButton,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography,
} from '@mui/material';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import dayjs from 'dayjs';

import { OverallGradeCircle } from '../../../../components/metric-displays';
import { ActionButton } from '../../../../components/buttons';
import { DropdownMenu } from '../../../../components/menus';
import { LeagueIcon } from '../../../../config/leagues';

export function ModelCard(
    {model_index,model_name,league,info_display,created,overall_grade,handleRename,handleDelete,handleSummary,handlePredictions}
){

    const action = (
    <ModelActions 
    model_index={model_index}
    model_name={model_name}
    handleRename={handleRename} 
    handleDelete={handleDelete}
    />)

    return (
        
        <Card sx={{borderRadius: 2}}>
            <CardHeader
                avatar={<LeagueIcon league={league} sx={{fontSize:"2rem"}}/>}
                action={action}
                title={model_name}
                subheader={info_display}
            />

            <CardContent>
                <Stack alignItems="center">
                    <Box sx={{height: 100,width: 100}}>
                        <OverallGradeCircle grade={overall_grade} />
                    </Box>
                </Stack>
            </CardContent>

            <CardActions>
                <Stack direction="column" spacing={1} width="100%">
                <Stack direction="row" spacing={1} justifyContent="center" width="100%">
                    <ActionButton onClick={() => handleSummary(model_index)}>Summary</ActionButton>
                    <ActionButton onClick={() => handlePredictions(model_index)}>Predictions</ActionButton>
                </Stack>
                <Typography variant="caption">
                    {"Created: " + dayjs(new Date(created).toLocaleString()).format("LLL")}
                </Typography>
                </Stack>
                
            </CardActions>
        </Card>
    )
}

function ModelActions({model_index,model_name,handleRename,handleDelete}) {

    const handleActionsClicked = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    }

    ////////////////////////////////////////////////////////
    // Menu setup
    ////////////////////////////////////////////////////////
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
        
    };

    return (
        <>
        <IconButton 
        aria-label="actions"
        onClick={handleActionsClicked}>
            <MoreVertIcon />
        </IconButton>

        <DropdownMenu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            sx={{
                '& .MuiPaper-root': {
                    mt: 1
                }
            }}
        >   
            <ListItem key={"Rename"} disablePadding>
                <ListItemButton onClick={()=>{handleRename(model_index,model_name);handleClose();}}>
                <ListItemIcon>
                    <EditIcon />
                </ListItemIcon>
                <ListItemText primary={"Rename"} />
                </ListItemButton>
            </ListItem>
            <ListItem key={"Delete"} disablePadding>
                <ListItemButton onClick={()=>{handleDelete(model_index);handleClose();}}>
                <ListItemIcon>
                    <DeleteIcon />
                </ListItemIcon>
                <ListItemText primary={"Delete"} />
                </ListItemButton>
            </ListItem>
        </DropdownMenu>

        </>
    )
}