import { useNavigate } from 'react-router-dom';
import { 
    Typography, 
    Button,
    Stack
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import { ResponsiveContainer } from '../../../components/responsive';

export default function PaymentSuccess() {
    const navigate = useNavigate();

    const redirectToPayments = () => {
        navigate('/profile',
            {state : {selectedTab : 'Payments'}}
        )
    }
    return (
        <ResponsiveContainer>
            <Stack width="100%" alignItems="center">
                <Stack direction="column" alignItems="center" sx={{width: 500}} spacing={2}>
                    <CheckCircleIcon color="success" fontSize="large" />
                    <Typography variant="h4">Payment Successful</Typography>

                    <Button onClick={redirectToPayments}
                    variant="contained"
                    endIcon={<ArrowRightAltIcon />}
                    >
                        View Subscription
                    </Button>
                </Stack>
            </Stack>

        </ResponsiveContainer>
    )
}