import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  Box,
  Divider,
  Stack,
  Typography
} from '@mui/material';

import dayjs from 'dayjs';

import { TeamLogo } from '../../../../components/graphics/team-logo';
import { teamScheduleRequest } from '../../../../hooks/api/team';
import { playerScheduleRequest } from '../../../../hooks/api/player';

const largeLogoWidth = 40;
const smallLogoWidth = 25;

export function TeamEventDisplay(
    {league,selectedDate,setDisabled,contentLocked,setContentLocked}
){
    const [events, setEvents] = useState([]);

    const handleScheduleResponse = (response) => {
        setEvents(response.data.games);
        setDisabled(response.data.games.length === 0 || contentLocked);
    }
    const handleScheduleError = (error) => {
        if (error.response) {
        if (error.response.status === 401) {

            // TODO - Handle unauthorized
            setContentLocked(true);

        } else {
            // Something went wrong
        }
        }
    }

    useEffect(() => {
        const params = {
        'league': league,
        'date': parseInt(selectedDate.format('YYYYMMDD'))
        }
        if (league.length !== 0) {
        teamScheduleRequest(params,handleScheduleResponse,handleScheduleError);
        }
    }, [selectedDate]);


    return (
        <Stack direction="column" spacing={1} alignItems="center" width="100%" height="100%">
            {events.length ?
            (
                <>
                {events.map((event, index) => (
                    <TeamEventBox key={"Game" + index} eventData={event} league={league} />
                ))}
                </>
            )
            :
            (
              <Stack alignItems='center' justifyContent='center' height="100%" width="100%" 
              sx={{borderRadius: 3,backgroundColor: "background.default"}}
              >
                  <Typography variant="body">
                  No Games
                  </Typography>
              </Stack>
            )
            }

        </Stack>
    )
}

function TeamEventBox({ eventData, league }) {

    const theme = useTheme();
  
    const startTime = dayjs(new Date(eventData['startTime']).toLocaleString("en-US", { timeZone: "America/New_York" }));
  
    return (
      <>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          divider={<Divider orientation="vertical" flexItem />}
          sx={{
            width: "100%",
            boxShadow: 1,
            borderRadius: 2,
            m: 1,
            p: 1,
            backgroundColor: theme.palette.background.paper
          }}
        >
  
          <Stack direction="column" alignItems="flex-start" spacing={1}
            sx={{ pl: 1, flexGrow: 1 }}>
            <Stack direction="row" justifyContent="center" spacing={1}>
              <TeamLogo team={eventData['away location'] + " " + eventData['away nickname']} league={league} width={smallLogoWidth} />
              <Box>
                {league.includes("NCAA") ?
                  (
                    <>
                      {eventData['away location']}
                    </>
                  )
                  :
                  (
                    <>
                      {eventData['away nickname']}
                    </>
                  )
                }
              </Box>
            </Stack>
            <Stack direction="row" justifyContent="center" spacing={1}>
              <TeamLogo team={eventData['home location'] + " " + eventData['home nickname']} league={league} width={smallLogoWidth} />
              <Box>
                {league.includes("NCAA") ?
                  (
                    <>
                      {eventData['home location']}
                    </>
                  )
                  :
                  (
                    <>
                      {eventData['home nickname']}
                    </>
                  )
                }
              </Box>
  
            </Stack>
          </Stack>
  
          <Box sx={{ pr: 1 }}>
            <Typography variant="subtitle2">
              {startTime.format("ddd, MMM DD")}
            </Typography>
  
          </Box>
  
        </Stack>
      </>
    )
}

export function PlayerEventDisplay(
    {league,position,selectedDate,setDisabled,contentLocked,setContentLocked}
) {
    const [events, setEvents] = useState([]);
  
    const handleScheduleResponse = (response) => {
      setEvents(response.data.events);
      setDisabled(response.data.games.length === 0 || contentLocked);
      
    }
    const handleScheduleError = (error) => {
      if (error.response) {
        if (error.response.status === 401) {
  
          // TODO - Handle unauthorized
          setContentLocked(true);
  
        } else {
          // Something went wrong
        }
      }
    }
  
    useEffect(() => {
      const params = {
        'league': league,
        'position': position,
        'date': parseInt(selectedDate.format('YYYYMMDD'))
      }
      if (league.length !== 0) {
        playerScheduleRequest(params,handleScheduleResponse,handleScheduleError);
      }
    }, [selectedDate]);
  
    return (
        <Stack direction="column" spacing={1} alignItems="center" width="100%" height="100%">
        {events.length ?
            (
            <>
                {events.map((event, index) => (
                <PlayerEventBox key={"Player" + index} event={event} league={league} />
                ))}
            </>
            )
            :
            (
            <Stack alignItems='center' justifyContent='center' height="100%" width="100%" 
              sx={{borderRadius: 3,backgroundColor: "background.default"}}
              >
                  <Typography variant="body">
                  No Games
                  </Typography>
              </Stack>
            )
        }

        </Stack>
    )
}

function PlayerEventBox({ event,league }) {

    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

    const startTime = dayjs(new Date(event['startTime']).toLocaleString("en-US", { timeZone: "America/New_York" }));
    
    const logoWidth = largeScreen ? largeLogoWidth : smallLogoWidth;
  
    return (
      <>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          divider={<Divider orientation="vertical" flexItem />}
          sx={{
            width: "100%",
            boxShadow: 1,
            borderRadius: 2,
            m: 1,
            p: 1,
            backgroundColor: theme.palette.background.paper
          }}
        >
          <Stack direction="column" alignItems="flex-start" spacing={1} id="event-container"
            sx={{ pl: 1, pt:1, pb:1, flexGrow: 1 }}>
            <Box sx={{textAlign: 'center',width: '100%'}}>
              <Stack direction="row" spacing={0.5} justifyContent="center" alignItems="center">
                <TeamLogo team={event['away']['location'] + " " + event['away']['nickname']} league={league} width={logoWidth} />
                <Typography variant="h6">{event['away']['abbreviation']}</Typography>
                <Typography variant="h6" sx={{pl:1,pr:1}}>{"@"}</Typography>
                <TeamLogo team={event['home']['location'] + " " + event['home']['nickname']} league={league} width={logoWidth} />
                <Typography variant="h6">{event['home']['abbreviation']}</Typography>
              </Stack>
              <Typography variant="subtitle2">
                {startTime.format("ddd, MMM DD")}
              </Typography>
            </Box>
            <Divider orientation="horizontal" flexItem sx={{ borderBottomWidth: 2 }}/>
  
            {/* Iterate over players in this event and display */}
            {event['players'].map((player, index) => (
              <PlayerBox key={"Player" + index} player={player} league={league} />
            ))}
  
          </Stack>
      </Stack>
      </>
    )
  }
  
  function PlayerBox({player,league}) {
  
    return (
      <>
        <Stack direction="row" spacing={1}>
          <TeamLogo team={player['location'] + " " + player['nickname']} league={league} width={smallLogoWidth} />
          <Box>
            {player['firstName']+" "+player["lastName"]}
          </Box>
        </Stack>
      </>
    )
  }