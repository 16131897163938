
import {
    Alert,
    Button,
    IconButton,
    Snackbar
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

export function SuccessSnackbar({open,setOpen,message,viewFunction=null}){

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

    // 20 seconds
    const autoHideDuration_milliseconds=20000;

    const action = (
        <>
        {viewFunction &&
            <>
            <Button color="inherit" variant="outlined" size="small" onClick={() => {viewFunction()}}>
            View
            </Button>
            </>
        }

        {/* Always chose close button */}
        <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
        >
            <CloseIcon fontSize="small" />
        </IconButton>
        </>
      );

    return(
        <>
            <Snackbar open={open} autoHideDuration={autoHideDuration_milliseconds} onClose={handleSnackbarClose}>
                <Alert
                severity="success"
                variant="filled"
                onClose={handleSnackbarClose}
                action={action}
                sx={{ width: '100%' }}
                >
                {message}
                </Alert>
            </Snackbar>
        </>
    )
}

export function SubscriptionConflictSnackbar({open,setOpen}){

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

    // 20 seconds
    const autoHideDuration_milliseconds=20000;

    const navigate = useNavigate();

    const action = (
        <>
        <Button color="inherit" variant="outlined" size="small" onClick={() => {navigate('/profile',{state : {selectedTab : "Payments"}})}}>
          View
        </Button>
        <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
        >
            <CloseIcon fontSize="small" />
        </IconButton>
        </>
      );

    return(
        <>
            <Snackbar open={open} autoHideDuration={autoHideDuration_milliseconds} onClose={handleSnackbarClose}>
                <Alert
                severity="error"
                variant="filled"
                onClose={handleSnackbarClose}
                action={action}
                sx={{ width: '100%' }}
                >
                Existing subscription matches requested subscription
                </Alert>
            </Snackbar>
        </>
    )
}