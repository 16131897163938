import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { 
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    Stack,
    Typography
} from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// Naming convention:
// historical_profitability refers to top level historical prof data
// bets_tracker refers to team tracker than contains spreads, totals, and cumulative
// bet_history refers to the common structure of bet data that includes units,
// win, loss, push, and win_pct fields always

export function TeamBetHistory({bets_tracker}){

    return (
        <Stack direction="row" spacing={{xs:0.5,sm:1}} justifyContent="center">
            <BetHistoryCard 
            title={"Spreads"}
            bet_history={bets_tracker['spreads']}
            />
            <BetHistoryCard 
            title={"Totals"}
            bet_history={bets_tracker['totals']}
            />
            <BetHistoryCard 
            title={"Cumulative"}
            bet_history={bets_tracker['cumulative']}
            />
        </Stack>
    )
}

export function PlayerBetHistory({historical_profitability}){

    return (
        <Stack direction="row" spacing={1} justifyContent="center" alignItems="center" width="100%" height="100%">
            <BetHistoryCard 
            title={"All"}
            bet_history={historical_profitability['all_bets']}
            />
            <BetHistoryCard 
            title={"Value"}
            bet_history={historical_profitability['value_bets']}
            />
        </Stack>
    )
}

export function BetHistoryCard({title,bet_history}){

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const {units,win,loss,push,win_pct} = bet_history;

    // Default to no color schema for value of 0 units
    let color_scheme = null;
    let units_change = units !== 0;
    if ( units > 0 ) {
        color_scheme = theme.palette.success.dark;
    }
    else if (units < 0) {
        color_scheme = theme.palette.error.dark;
    }

    const Icon = units > 0 ? ArrowDropUpIcon : ArrowDropDownIcon;

    const bottomTextVariant = isMobile ? "caption" : "body2";

    const win_pct_display = Math.round(win_pct*10000)/100;

    return (
        <Card sx={{p:1,width:'100%'}}>
            <CardHeader
                title={title}
                titleTypographyProps={{variant: isMobile ? "body2" : "body" }}
                sx={{p:0}}
            />

            <CardContent sx={{ p: {xs:0,sm:1.5} }}>
                <Stack direction="row" alignItems="center" justifyContent="center">
                    <Typography variant="h6" color={color_scheme}>
                        {units+"u"}
                    </Typography>
                    {units_change && <Icon sx={{color: color_scheme}} fontSize={isMobile ? "small" : "large"} />}
                </Stack>
            </CardContent>

            <CardActions sx={{p: {xs:0.1,sm:0.5}}}>
                <Stack direction={isMobile ? "column" : "row"} spacing={{xs:0.5,sm:1}} width={"100%"} justifyContent="center" alignItems="center"
                divider={isMobile ? <></> : <Divider orientation="vertical" flexItem />}
                >
                    <Typography variant={bottomTextVariant}>{win+"-"+loss+"-"+push}</Typography>
                    <Typography variant={bottomTextVariant}>{win_pct_display}%</Typography>
                </Stack>
            </CardActions>

        </Card>
    )
}