import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    DialogContent,
    IconButton,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import { Login,Signup,ForgotPassword } from './user';
import { SuccessSnackbar } from '../../../components/snackbars';
import { sendVerificationEmailAndRedirect } from '../../../hooks/api-wrappers';
import { accountForgotPasswordRequest, accountLoginRequest, accountRegisterRequest } from '../../../hooks/api/account';
import { useAuth } from '../../../hooks/auth';
import { ResponsiveDialog } from '../../../components/responsive';

export function UserPopup({ open, setOpen }) {

    const [snackBarOpen,setSnackBarOpen] = useState(false);

    return (
        <>
            {open ?
                (
                    <>
                        <UserPopupContent open={open} setOpen={setOpen}
                            setSnackBarOpen={setSnackBarOpen} />
                    </>
                ) :
                (
                    <>
                    <SuccessSnackbar open={snackBarOpen} setOpen={setSnackBarOpen}
                        message={"You are now logged in"} />
                    </>
                )
            }
        </>
    )
}


function UserPopupContent({ open, setOpen, setSnackBarOpen }) {

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <ResponsiveDialog
                onClose={handleClose}
                open={open}
            >
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.primary.main,
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <Box component="div" display="flex" justifyContent="center" alignItems="center" id="top-level-box"
                    sx={{pr: 5,pl: 5}}
                    >
                        <UserPopupContentPages setOpen={setOpen} setSnackBarOpen={setSnackBarOpen} />
                    </Box>
                </DialogContent>
            </ResponsiveDialog>
        </>
    );
}

function UserPopupContentPages({setOpen,setSnackBarOpen}){
    const auth = useAuth();
    const navigate = useNavigate();

    const [selectedPage, setSelectedPage] = useState('SignIn');
    const [loginError, setLoginError] = useState(null);
    const [signupError, setSignupError] = useState(null);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [forgotPasswordEmailSent,setForgotPasswordEmailSent] = useState(false);

    const handleSignInResponse = (response) => {
        setLoginError(null);
        setSignupError(null);
        auth.setAuthenticated(true);
        setOpen(false);
        setSnackBarOpen(true);

        // Note: Not processing the expired_subscription and expired_trial
        // response fields here because we are not redirecting to landing
        // page
    }
    const handleSignInError = (error) => {
        setSubmitDisabled(false);
        if (error.response.status === 401) {
            sendVerificationEmailAndRedirect(navigate);
        }
        else if (error.response.data) {
            setLoginError(error.response.data.message);
        }
        else {
            setLoginError('Failed to login');
        }
    }

    const handleSubmitOnSignIn = (event,userData) => {
        if (userData.google_account === false) {
            if (submitDisabled) {
                return;
            }
            event.preventDefault();
        }
        setSubmitDisabled(true);

        const params = {
            email: userData.email,
            password: userData.password,
            google_account: userData.google_account
        }

        accountLoginRequest(params,handleSignInResponse,handleSignInError);
    }
    
    const handleSignupButtonOnSignIn = () => {
        setSelectedPage('SignUp');
    }

    const handleForgotPasswordButtonOnSignIn = () => {
        setSelectedPage('ForgotPassword');
    }

    const handleSignUpResponse = (response) => {
        setLoginError(null);
        setSignupError(null);
        auth.setAuthenticated(true);
        setOpen(false);
        setSnackBarOpen(true);
    }
    const handleSignUpError = (error) => {
        if (error.response.status === 401) {
            sendVerificationEmailAndRedirect(navigate);
        }
        else if (error.response.data) {
            setSignupError(error.response.data.message);
        }
        else {
            setSignupError('Failed to signup');
        }
        setSubmitDisabled(false);
    }

    const handleSubmitOnSignUp = (event,userData) => {
        if (userData.google_account === false) {
            if (submitDisabled) {
                return;
            }
            event.preventDefault();
        }

        setSubmitDisabled(true);
        
        const params = {
            first: userData.first,
            last: userData.last,
            username: userData.username,
            email: userData.email,
            password: userData.password,
            promotional: userData.promotional,
            google_account: userData.google_account,
            recaptcha_response: userData.recaptcha_response
        }

        accountRegisterRequest(params,handleSignUpResponse,handleSignUpError);
    }

    const handleForgotPasswordResponse = (response) => {
        setForgotPasswordEmailSent(true);
    }
    const handleForgotPasswordError = (error) => {}

    const handleSubmitOnForgotPassword = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const params = {
            email: data.get('email'),
        }
        accountForgotPasswordRequest(params,handleForgotPasswordResponse,handleForgotPasswordError);
    }

    const handleSignInButton = () => {
        setSelectedPage('SignIn');
    }

    const renderContent = (selectedPage) => {
        switch (selectedPage) {
            case 'SignIn':
                return <Login handleSubmit={handleSubmitOnSignIn} handleSignupButton={handleSignupButtonOnSignIn} 
                        handleForgotPasswordButton={handleForgotPasswordButtonOnSignIn} submitDisabled={submitDisabled} 
                        error={loginError} setError={setLoginError} />
            case 'SignUp':
                return <Signup handleSubmit={handleSubmitOnSignUp} handleSignInButton={handleSignInButton} 
                    submitDisabled={submitDisabled} error={signupError} setError={setSignupError} />
            case 'ForgotPassword':
                return <ForgotPassword handleSubmit={handleSubmitOnForgotPassword} handleSignInButton={handleSignInButton} 
                    emailSent={forgotPasswordEmailSent} />
            default:
                return <Login />
        }
    }
    return (
        <>
            {renderContent(selectedPage)}
        </>
    )
}